import theme from '../theme';

const dialogs = {
    paper: {
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: 10,
        padding: theme.spacing(4),
        width: 780,
        maxWidth: 780,
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(2),
            width: 'auto',
            margin: theme.spacing(1),
            maxWidth: '95% ! important',
        },
    },
    title: {
        textAlign: 'center',
        fontSize: 42,
        fontWeight: 700,
        marginBottom: theme.spacing(2),
        color: theme.palette.primary.main,
        [theme.breakpoints.down('xs')]: {
            fontSize: 30,
            marginTop: theme.spacing(4),
        },
    },
    desc: {
        textAlign: 'center',
        marginBottom: 0,
    },
    actions: {
        display: 'flex',
        justifyContent: 'center',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(2),
    },
};

export default dialogs;
