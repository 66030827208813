import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import commonStyles from '../../styles/index';

const styles = () => ({
    ...commonStyles.buttons,
});

const useStyles = makeStyles((theme) => styles(theme));

const ButtonComponent = ({
    t,
    messageKey,
    onClick,
    message,
    active,
    fullWidth,
    large,
    disabled,
    gtmId,
}) => {
    const classes = useStyles();
    const handleClick = () => {
        if (gtmId !== '') {
            window.dataLayer.push({
                event: `${gtmId}-click`,
                'data-gtmid': gtmId,
            });
        }
        onClick();
    };
    const extraProps = {};
    if (gtmId !== '') {
        extraProps['data-gtmid'] = gtmId;
    }
    return (
        <Button
            disabled={disabled}
            className={classNames(
                classes.root,
                active && classes.active,
                fullWidth && classes.fullWidth,
                large && classes.large,
                disabled && classes.disabled,
            )}
            onClick={() => handleClick()}
            classes={{
                label: classes.label,
            }}
            {...extraProps}
        >
            {messageKey !== '' && (
                <span dangerouslySetInnerHTML={{ __html: t(messageKey) }} />
            )}
            {message !== '' && messageKey === '' && (
                <span dangerouslySetInnerHTML={{ __html: message }} />
            )}
        </Button>
    );
};

ButtonComponent.defaultProps = {
    message: '',
    messageKey: '',
    gtmId: '',
    active: false,
    fullWidth: false,
    large: false,
    disabled: false,
};

ButtonComponent.propTypes = {
    t: PropTypes.func.isRequired,
    messageKey: PropTypes.string,
    message: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    active: PropTypes.bool,
    fullWidth: PropTypes.bool,
    large: PropTypes.bool,
    disabled: PropTypes.bool,
    gtmId: PropTypes.string,
};

export default withTranslation()(ButtonComponent);
