import React from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Nav from './Nav';
import NavMobile from './NavMobile';

const NavContainer = ({ setSpyEnabled, isVisible, lastScroll }) => {
    const matches = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    if (!matches) {
        return (
            <Nav
                setSpyEnabled={(enabled) => setSpyEnabled(enabled)}
                isVisible={isVisible}
                lastScroll={lastScroll}
            />
        );
    }
    return (
        <NavMobile
            setSpyEnabled={(enabled) => setSpyEnabled(enabled)}
            matches={matches}
            isVisible={isVisible}
            lastScroll={lastScroll}
        />
    );
};

NavContainer.propTypes = {
    setSpyEnabled: PropTypes.func.isRequired,
    lastScroll: PropTypes.number.isRequired,
    isVisible: PropTypes.bool.isRequired,
};

export default NavContainer;
