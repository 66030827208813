import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function PhoneSvg(props) {
    const finalProps = {
        ...props,
        viewBox: '0 0 26.915 26.915',
    };
    return (
        <SvgIcon {...finalProps}>
            <g>
                <path d="M15.525,15.524c-2.664,2.664-5.748,5.21-6.967,3.99-1.746-1.745-2.822-3.266-6.67-.173S1,24.5,2.686,26.188c1.951,1.951,9.225.1,16.415-7.086S28.141,4.637,26.188,2.685c-1.691-1.691-3.753-4.646-6.845-.8s-1.574,4.923.173,6.669C20.734,9.776,18.188,12.86,15.525,15.524Z" />
            </g>
        </SvgIcon>
    );
}

export default PhoneSvg;
