import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Link } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import theme from '../../../theme';
import footerLogo from '../../../img/svg/footerLogo.svg';

const styles = () => {
    return {
        root: {
            background: `linear-gradient(to left,${theme.palette.tertiary.main}, ${theme.palette.senary.main})`,
            width: '100%',
            height: '100%',
            padding: theme.spacing(6, 0),
            color: 'white',
        },
        container: {
            [theme.breakpoints.down('md')]: {
                padding: theme.spacing(0, 4),
            },
            [theme.breakpoints.down('xs')]: {
                textAlign: 'center',
            },
        },
        logo: {
            width: 220,
            height: 'auto',
            [theme.breakpoints.down('xs')]: {
                width: 150,
            },
        },
        tagline: {
            width: '60%',
            fontSize: 14,
            marginTop: theme.spacing(3),
            [theme.breakpoints.down('md')]: {
                fontSize: 11,
            },
            [theme.breakpoints.down('xs')]: {
                width: '100%',
            },
        },
        copyright: {
            fontSize: 12,
            fontWeight: 'lighter',
            marginTop: theme.spacing(3),
        },
        footerLineItem: {
            height: theme.spacing(2),
            backgroundColor: theme.palette.quinary.main,
        },
        list: {
            marginTop: theme.spacing(3),
            listStyle: 'none',
            padding: 0,
            margin: 0,
            [theme.breakpoints.down('xs')]: {
                marginTop: theme.spacing(1),
            },
        },
        link: {
            fontSize: 12,
            color: 'white',
            display: 'block',
            marginBottom: theme.spacing(2),
            [theme.breakpoints.down('xs')]: {
                marginBottom: theme.spacing(1),
            },
        },
        address: {
            fontSize: 14,
            fontWeight: 'lighter',
            display: 'block',
            marginBottom: 12,
            [theme.breakpoints.down('xs')]: {
                marginBottom: theme.spacing(1),
            },
        },
        phone: {
            fontSize: 18,
            color: 'white',
            display: 'block',
            marginBottom: 7,
            [theme.breakpoints.down('md')]: {
                fontSize: 15,
                marginBottom: 11,
            },
            [theme.breakpoints.down('xs')]: {
                marginBottom: theme.spacing(1),
            },
        },
        mail: {
            fontSize: 14,
            color: 'white',
            fontWeight: 'lighter',
            display: 'block',
            marginBottom: theme.spacing(2),
        },
        gridTitle: {
            [theme.breakpoints.down('xs')]: {
                marginTop: theme.spacing(2),
            },
        },
        gridTitleSmall: {
            [theme.breakpoints.down('xs')]: {
                marginTop: theme.spacing(1),
            },
        },
        copyrightMobile: {
            fontSize: 12,
            fontWeight: 'lighter',
            marginTop: theme.spacing(1),
        },
    };
};

const useStyles = makeStyles(() => styles(theme));

const Footer = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const currentTime = new Date();
    const year = currentTime.getFullYear();
    const matches = useMediaQuery(() => theme.breakpoints.down('sm'));
    return (
        <>
            <section className={classes.root}>
                <Container className={classes.container} maxWidth="lg">
                    <Grid container spacing={0}>
                        <Grid item sm={5} md={6} xs={12}>
                            <img
                                alt="logo"
                                className={classes.logo}
                                src={footerLogo}
                            />
                            <Typography className={classes.tagline}>
                                {t('footer.tagline')}
                            </Typography>
                            {!matches && (
                                <Typography className={classes.copyright}>
                                    &copy;
                                    {`${year} Solution4You`}
                                </Typography>
                            )}
                        </Grid>
                        <Grid item container sm={7} md={6} xs={12}>
                            <Grid item sm={6} xs={12}>
                                <Typography className={classes.gridTitle}>
                                    {t('footer.infos.title')}
                                </Typography>
                                <ul className={classes.list}>
                                    <li>
                                        <Link
                                            className={classes.link}
                                            href={t('footer.infos.privateLink')}
                                            target="_blank"
                                        >
                                            {t('footer.infos.private')}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            className={classes.link}
                                            href={t('footer.infos.cookiesLink')}
                                            target="_blank"
                                        >
                                            {t('footer.infos.cookies')}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            className={classes.link}
                                            href={t('footer.infos.conditionsLink')}
                                            target="_blank"
                                        >
                                            {t('footer.infos.conditions')}
                                        </Link>
                                    </li>
                                </ul>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <Typography className={classes.gridTitleSmall}>
                                    {t('footer.contact.title')}
                                </Typography>
                                <ul className={classes.list}>
                                    <li className={classes.address}>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: t('contact.address'),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <Link
                                            className={classes.phone}
                                            href={`tel:${t(
                                                'contact.phoneNumber',
                                            )}`}
                                        >
                                            {t('contact.phoneNumber')}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            className={classes.mail}
                                            href={`mailto:${t('contact.mail')}`}
                                        >
                                            {t('contact.mail')}
                                        </Link>
                                    </li>
                                </ul>
                            </Grid>
                            {matches && (
                                <Grid item xs={12}>
                                    <Typography
                                        className={classes.copyrightMobile}
                                    >
                                        &copy;
                                        {`${year} Solution4You`}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Container>
            </section>
            <Grid container spacing={0}>
                <Grid item xs={7} className={classes.footerLineItem} />
                <Grid
                    item
                    xs={1}
                    className={classes.footerLineItem}
                    style={{
                        backgroundColor: theme.palette.primary.main,
                    }}
                />
                <Grid
                    item
                    xs={1}
                    className={classes.footerLineItem}
                    style={{
                        backgroundColor: theme.palette.secondary.main,
                    }}
                />
                <Grid
                    item
                    xs={1}
                    className={classes.footerLineItem}
                    style={{
                        backgroundColor: theme.palette.tertiary.main,
                    }}
                />
                <Grid
                    item
                    xs={1}
                    className={classes.footerLineItem}
                    style={{
                        backgroundColor: theme.palette.quaternary.main,
                    }}
                />
                <Grid item xs={1} className={classes.footerLineItem} />
            </Grid>
        </>
    );
};

export default Footer;
