import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { withStyles, Container, Grid } from '@material-ui/core';

import { sections } from '../config';
import { navigate } from '../../../utils';

import LogoHorizontal from '../../../img/svg/LogoHorizontal.svg';
import LogoVertical from '../../../img/svg/LogoVertical.svg';

import Button from '../../../components/buttons';
import LanguageSelector from './LanguageSelector';

const menuHeight = 170;

const styles = (theme) => {
    return {
        root: {
            position: 'fixed',
            height: menuHeight,
            width: '100%',
            top: 0,
            transition: theme.transition([
                'height',
                'margin-top',
                'background-color',
            ]),
            backgroundColor: 'transparent',
            marginTop: -menuHeight,
            zIndex: 1000,
        },
        container: {
            padding: 0,
            height: '100%',
        },
        nav: {
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            [theme.breakpoints.down('md')]: {
                padding: theme.spacing(0, 4),
                '& button': {
                    padding: '10px 7px 13px 10px',
                },
                '& button span': {
                    fontSize: 13,
                    lineHeight: '14px',
                },
            },
        },
        visible: {
            height: 100,
            marginTop: '0',
            backgroundColor: theme.palette.senary.main,
        },
        topPosition: {
            height: menuHeight,
            backgroundColor: 'transparent',
        },
        logoContainer: {
            height: '100%',
            width: 200,
            position: 'relative',
            outline: 'none',
            boxShadow: 'none',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
        },
        logo: {
            width: '100%',
            height: 170,
            transition: theme.transition(['opacity']),
            opacity: 0,
            position: 'absolute',
            top: 0,
            left: -5000,
        },
        logoHorizontal: {
            width: 185,
            height: 'auto',
            top: 4,
        },
        logoVisible: {
            opacity: 1,
            left: 0,
        },
        navButtons: {
            '& button .MuiButton-label': {
                fontSize: '15px !important',
            },
        },
    };
};

class HomeNav extends Component {
    handleClick(sectionKey) {
        const {
            setSpyEnabled,
            match: {
                params: { language },
            },
            history: { push },
        } = this.props;
        navigate(setSpyEnabled, language, sectionKey, push);
    }

    render() {
        const {
            t,
            classes,
            match: {
                params: { section },
            },
            isVisible,
            lastScroll,
            setSpyEnabled,
        } = this.props;
        const isOnTop = lastScroll <= menuHeight;
        const homeSection = sections(t).find((s) => s.key === 'home');
        return (
            <div
                className={classNames(
                    classes.root,
                    isVisible && classes.visible,
                    isOnTop && classes.topPosition,
                )}
            >
                <Container maxWidth="lg" className={classes.container}>
                    <nav className={classes.nav}>
                        <Grid
                            item
                            container
                            spacing={0}
                            className={classes.container}
                        >
                            <Grid
                                item
                                container
                                xs={11}
                                spacing={0}
                                className={classes.container}
                            >
                                <Grid item xs={3}>
                                    <div
                                        key={homeSection.key}
                                        role="button"
                                        tabIndex="0"
                                        className={classes.logoContainer}
                                        onClick={() =>
                                            this.handleClick(homeSection.key)
                                        }
                                    >
                                        <img
                                            alt="logo"
                                            className={classNames(
                                                classes.logo,
                                                isOnTop && classes.logoVisible,
                                            )}
                                            src={LogoVertical}
                                        />
                                        <img
                                            alt="logo"
                                            className={classNames(
                                                classes.logo,
                                                classes.logoHorizontal,
                                                !isOnTop && classes.logoVisible,
                                            )}
                                            src={LogoHorizontal}
                                        />
                                    </div>
                                </Grid>

                                <Grid
                                    item
                                    xs={9}
                                    container
                                    display="flex"
                                    className={classes.navButtons}
                                >
                                    {sections(t, setSpyEnabled)
                                        .filter((s) => s.key !== 'home')
                                        .map((s) => (
                                            <Grid
                                                item
                                                xs={3}
                                                key={s.key}
                                                container
                                                display="flex"
                                                alignItems="center"
                                                justify="center"
                                            >
                                                <Button
                                                    onClick={() =>
                                                        this.handleClick(s.key)
                                                    }
                                                    message={s.label}
                                                    active={
                                                        section === s.key ||
                                                        s.active
                                                    }
                                                />
                                            </Grid>
                                        ))}
                                </Grid>
                            </Grid>
                            <Grid item xs={1} container justify="flex-end">
                                <LanguageSelector />
                            </Grid>
                        </Grid>
                    </nav>
                </Container>
            </div>
        );
    }
}

HomeNav.propTypes = {
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    setSpyEnabled: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    lastScroll: PropTypes.number.isRequired,
    isVisible: PropTypes.bool.isRequired,
};

export default withRouter(withStyles(styles)(withTranslation()(HomeNav)));
