import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { withStyles, Container, Grid, Button } from '@material-ui/core';
import { HamburgerSqueeze } from 'react-animated-burgers';

import { sections } from '../config';
import { menuHeight } from '../constants';
import { navigate } from '../../../utils';

import LogoHorizontal from '../../../img/svg/footerLogo.svg';

import LanguageSelector from './LanguageSelector';

const styles = (theme) => {
    return {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0)',
            zIndex: 10,
            transition: theme.transition(['background-color', 'visibility']),
            visibility: 'hidden',
        },
        overlayActive: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            visibility: 'visible',
        },
        root: {
            position: 'fixed',
            maxHeight: menuHeight,
            width: '100%',
            top: 0,
            transition: theme.transition([
                'height',
                'max-height',
                'background-color',
            ]),
            backgroundColor: theme.palette.senary.main,
            zIndex: 1000,
        },
        container: {
            padding: 0,
            height: '100%',
        },
        nav: {
            display: 'block',
            height: menuHeight,
            padding: theme.spacing(0, 2),
        },
        visible: {
            backgroundColor: theme.palette.senary.main,
        },
        active: {
            maxHeight: '100vh',
        },
        topPosition: {
            backgroundColor: 'transparent',
        },
        topPositionActive: {
            backgroundColor: theme.palette.senary.main,
        },
        logoButtonContainer: {
            height: '100%',
            width: 200,
            position: 'relative',
            outline: 'none',
            boxShadow: 'none',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
        },
        logoContainer: {
            height: '80%',
            width: 200,
            position: 'relative',
            outline: 'none',
            boxShadow: 'none',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
        },
        logo: {
            width: '80%',
            height: 'auto',
        },
        hamburger: {
            outline: 'none',
            boxShadow: 'none',
            userSelect: 'none',
            '-webkit-tap-highlight-color': 'transparent',
            '& span': {
                transition: theme.transition(['opacity']),
                backgroundColor: 'white',
                opacity: '0.352',
                '&:before': {
                    backgroundColor: 'white',
                },
                '&:after': {
                    backgroundColor: 'white',
                },
            },
        },
        hamburgerActive: {
            '& span': {
                opacity: 1,
            },
        },
        linksList: {
            listStyle: 'none',
            padding: 0,
            color: 'white',
            margin: theme.spacing(2, 2, 4, 2),
            maxHeight: 0,
            overflow: 'hidden',
            transition: theme.transition(['max-height']),
        },
        linksListActive: {
            maxHeight: '90vh',
        },
        linksListButton: {
            textTransform: 'none',
            width: '100%',
            color: 'rgba(255, 255, 255, 0.352)',
            textAlign: 'left',
            display: 'block',
            transition: theme.transition(['color']),
        },
        linksListButtonActive: {
            color: 'rgba(255, 255, 255, 1)',
        },
    };
};

class HomeNavMobile extends Component {
    constructor() {
        super();
        this.state = {
            isActive: false,
        };
    }

    handleClick(sectionKey) {
        const {
            setSpyEnabled,
            match: {
                params: { language },
            },
            history: { push },
        } = this.props;
        navigate(setSpyEnabled, language, sectionKey, push);
        this.toggleMenu();
    }

    toggleMenu() {
        const { isActive } = this.state;
        this.setState({
            isActive: !isActive,
        });
        document.body.classList.toggle('lock-scroll');
    }

    render() {
        const {
            t,
            classes,
            match: {
                params: { section },
            },
            isVisible,
            lastScroll,
            setSpyEnabled,
        } = this.props;
        const { isActive } = this.state;
        const isOnTop = lastScroll <= menuHeight;
        const homeSection = sections(t).find((s) => s.key === 'home');
        return (
            <>
                <div
                    className={classNames(
                        classes.overlay,
                        isActive && classes.overlayActive,
                    )}
                />
                <div
                    className={classNames(
                        classes.root,
                        (isVisible || isActive) && classes.visible,
                        isOnTop && classes.topPosition,
                        isOnTop && isActive && classes.topPositionActive,
                        isActive && classes.active,
                    )}
                >
                    <Container maxWidth="lg" className={classes.container}>
                        <nav className={classes.nav}>
                            <Grid
                                container
                                spacing={0}
                                className={classes.container}
                            >
                                <Grid
                                    item
                                    container
                                    xs={9}
                                    spacing={0}
                                    className={classes.container}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        className={classes.logoButtonContainer}
                                    >
                                        <div
                                            key={homeSection.key}
                                            role="button"
                                            tabIndex="0"
                                            className={classes.logoContainer}
                                            onClick={() =>
                                                this.handleClick(
                                                    homeSection.key,
                                                )
                                            }
                                        >
                                            <img
                                                alt="logo"
                                                className={classNames(
                                                    classes.logo,
                                                    classes.logoHorizontal,
                                                )}
                                                src={LogoHorizontal}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid item xs={3} container justify="flex-end">
                                    <Grid item container spacing={0}>
                                        <Grid
                                            item
                                            xs={6}
                                            container
                                            display="flex"
                                            alignItems="center"
                                            justify="flex-end"
                                        >
                                            <LanguageSelector />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            container
                                            display="flex"
                                            alignItems="center"
                                            justify="flex-end"
                                        >
                                            <HamburgerSqueeze
                                                isActive={isActive}
                                                buttonWidth={20}
                                                className={classNames(
                                                    classes.hamburger,
                                                    isActive &&
                                                        classes.hamburgerActive,
                                                )}
                                                toggleButton={() =>
                                                    this.toggleMenu()
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </nav>
                        <ul
                            className={classNames(
                                classes.linksList,
                                isActive && classes.linksListActive,
                            )}
                        >
                            {sections(t, setSpyEnabled)
                                .filter((s) => s.key !== 'home')
                                .map((s) => (
                                    <li key={s.key}>
                                        <Button
                                            onClick={() =>
                                                this.handleClick(s.key)
                                            }
                                            className={classNames(
                                                classes.linksListButton,
                                                (section === s.key ||
                                                    s.active) &&
                                                    classes.linksListButtonActive,
                                            )}
                                        >
                                            {s.labelMobile}
                                        </Button>
                                    </li>
                                ))}
                        </ul>
                    </Container>
                </div>
            </>
        );
    }
}

HomeNavMobile.propTypes = {
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    setSpyEnabled: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    lastScroll: PropTypes.number.isRequired,
    isVisible: PropTypes.bool.isRequired,
};

export default withRouter(withStyles(styles)(withTranslation()(HomeNavMobile)));
