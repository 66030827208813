import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

const TextMaskCustom = (props) => {
    const { inputRef, prefix, format, name, onChange, ...other } = props;
    const [isFocused, setIsFocused] = React.useState(false);
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name,
                        value: values.value,
                    },
                });
            }}
            prefix={prefix}
            format={format}
            onFocus={() => {
                other.onFocus();
                setIsFocused(true);
            }}
            allowEmptyFormatting={isFocused}
        />
    );
};

TextMaskCustom.defaultProps = {
    prefix: '',
    format: '',
};
TextMaskCustom.propTypes = {
    onChange: PropTypes.func.isRequired,
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    prefix: PropTypes.string,
    format: PropTypes.string,
};

export default TextMaskCustom;
