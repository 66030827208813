import buttons from './buttons';
import dialogs from './dialogs';
import forms from './forms';

const commonStyles = {
    forms,
    dialogs,
    buttons,
};

export default commonStyles;
