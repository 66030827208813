import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { Grid, Typography, Link } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';

import { getCookie, setCookie } from '../../../utils';
import Button from '../../../components/buttons';

const styles = (theme) => {
    return {
        root: {
            backgroundColor: theme.palette.tertiary.main,
            width: '100%',
            padding: theme.spacing(4, 0),
            color: 'white',
            position: 'fixed',
            bottom: '-100%',
            left: 0,
            zIndex: 10,
            transition: theme.transition(['bottom']),
            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(2, 0),
            },
        },
        visible: {
            bottom: 0,
        },
        link: {
            fontSize: '1rem',
            color: 'white',
            display: 'block',
            marginRight: theme.spacing(4),
            [theme.breakpoints.down('xs')]: {
                fontSize: 16,
            },
        },
        text: {
            [theme.breakpoints.down('xs')]: {
                fontSize: 16,
                marginBottom: theme.spacing(2),
            },
        },
        buttonContainer: {
            justifyContent: 'center',
        },
    };
};

class CookieBanner extends Component {
    constructor() {
        super();
        this.state = {
            hasCookie: true,
        };
    }

    componentDidMount() {
        this.setState({
            hasCookie: Boolean(getCookie('rgpd')),
        });
    }

    handleSetCookie() {
        this.setState({
            hasCookie: 'true',
        });
        setCookie('rgpd', true);
    }

    render() {
        const { classes, t } = this.props;
        const { hasCookie } = this.state;
        return (
            <section
                className={classNames(
                    classes.root,
                    !hasCookie && classes.visible,
                )}
            >
                <Container className={classes.container} maxWidth="lg">
                    <Grid container spacing={0}>
                        <Grid item xs={12} md={8} sm={6}>
                            <Typography className={classes.text}>
                                {t('cookieBanner.text')}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            container
                            alignItems="center"
                            justify="flex-end"
                            md={4}
                            sm={6}
                            xs={12}
                            className={classes.buttonContainer}
                        >
                            <Link
                                href={t('cookieBanner.infosLink')}
                                target="_blank"
                                className={classes.link}
                            >
                                {t('cookieBanner.infos')}
                            </Link>
                            <Button
                                onClick={() => this.handleSetCookie()}
                                message={t('cookieBanner.accept')}
                                active
                                large
                            />
                        </Grid>
                    </Grid>
                </Container>
            </section>
        );
    }
}

CookieBanner.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withTranslation()(CookieBanner));
