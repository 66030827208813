import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function PhoneSvg(props) {
    const finalProps = {
        ...props,
        viewBox: '0 0 28.654 30.4',
    };
    return (
        <SvgIcon {...finalProps}>
            <g>
                <path
                    transform="translate(-15.137 -12.16)"
                    d="M36.145,40.812A15.92,15.92,0,0,1,28.37,42.56c-7.163,0-13.233-5.242-13.233-13.628,0-9,6.245-16.772,15.944-16.772,7.6,0,12.71,5.329,12.71,12.623,0,6.551-3.67,10.394-7.774,10.394-1.75,0-3.365-1.179-3.233-3.843H32.61c-1.529,2.576-3.538,3.843-6.159,3.843-2.532,0-4.716-2.052-4.716-5.5A10.313,10.313,0,0,1,32.084,19.322a11.5,11.5,0,0,1,4.673.918l-1.483,7.906c-.654,3.32-.132,4.847,1.309,4.892,2.23.043,4.719-2.927,4.719-7.993,0-6.334-3.8-10.875-10.573-10.875-7.116,0-13.1,5.633-13.1,14.5,0,7.294,4.762,11.791,11.224,11.791a13.631,13.631,0,0,0,6.6-1.528Zm-2.4-18.868a7.541,7.541,0,0,0-2.183-.306c-3.845,0-6.946,3.626-6.946,7.862,0,1.922.96,3.32,2.884,3.32,2.532,0,4.847-3.233,5.327-5.939Z"
                />
            </g>
        </SvgIcon>
    );
}

export default PhoneSvg;
