import React from 'react';
import Hero from './components/Hero';
import Solutions from './components/Solutions';
import Benefits from './components/Benefits';
import Solution4energy from './components/Solution4energy';
import Contact from './components/Contact';
import theme from '../../theme';

export const sections = (t, setSpyEnabled, toggleCalculate) => [
    {
        key: 'home',
        colors: [theme.palette.senary.main, theme.palette.tertiary.main],
        gradiantOrientation: 'vertical',
        label: 'HOME',
        labelMobile: 'HOME',
        component: <Hero setSpyEnabled={setSpyEnabled} />,
    },
    {
        key: 'solutions',
        label: t('nav.solutions'),
        labelMobile: t('navMobile.solutions'),
        component: <Solutions />,
    },
    {
        key: 'solution4energy',
        colors: [theme.palette.tertiary.main, theme.palette.primary.light],
        gradiantOrientation: 'horizontal',
        label: t('nav.solutions4energy'),
        labelMobile: t('navMobile.solutions4energy'),
        component: (
            <Solution4energy toggleCalculate={() => toggleCalculate(true)} />
        ),
    },
    {
        key: 'benefits',
        label: t('nav.benefits'),
        labelMobile: t('navMobile.benefits'),
        component: <Benefits />,
    },
    {
        key: 'contact',
        active: true,
        label: t('nav.contact'),
        labelMobile: t('navMobile.contact'),
        component: <Contact />,
    },
];
