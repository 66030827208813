import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import fourIcons from '../../../img/svg/fourIcons.svg';
import Button from '../../../components/buttons';

const styles = (theme) => {
    return {
        root: {
            width: '100%',
            height: '100%',
            padding: '8vh 0',
            [theme.breakpoints.down('xs')]: {
                padding: '12vh 0',
                display: 'flex',
                alignContent: 'center',
                minHeight: '100vh',
            },
        },
        container: {
            height: '100%',
            width: '100%',
            padding: 0,
            display: 'flex',
            [theme.breakpoints.down('md')]: {
                padding: theme.spacing(0, 4),
            },
        },
        fourIconsContainer: {
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
        },
        fourIcons: {
            height: '40vh',
            width: 'auto',
            [theme.breakpoints.down('md')]: {
                height: '30vh',
            },
            [theme.breakpoints.down('sm')]: {
                height: '20vh',
            },
        },
        textContainer: {
            color: 'white',
            paddingLeft: '7%',
            paddingRight: '2%',
            [theme.breakpoints.down('xs')]: {
                padding: 0,
            },
        },
        title: {
            marginBottom: theme.spacing(4),
            fontWeight: 500,
            [theme.breakpoints.down('md')]: {
                fontSize: 30,
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: 26,
                marginBottom: theme.spacing(2),
            },
        },
        desc: {
            fontSize: 18,
            lineHeight: '30px',
            [theme.breakpoints.down('xs')]: {
                fontSize: 16,
                lineHeight: '18px',
            },

            marginBottom: theme.spacing(4),
        },
    };
};

const useStyles = makeStyles((theme) => styles(theme));

const Solution4energy = ({ toggleCalculate }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    return (
        <section className={classes.root}>
            <Container className={classes.container} maxWidth="lg">
                <Grid container alignItems="center">
                    <Grid
                        container
                        item
                        sm={5}
                        xs={12}
                        display="flex"
                        justify="center"
                        alignItems="center"
                        className={classes.fourIconsContainer}
                    >
                        <img
                            alt="fourIcons"
                            src={fourIcons}
                            className={classes.fourIcons}
                        />
                    </Grid>
                    <Grid item sm={7} xs={12} className={classes.textContainer}>
                        <Typography className={classes.title} variant="h2">
                            {t('solutions4energy.title')}
                        </Typography>
                        <Typography className={classes.desc}>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: t('solutions4energy.desc'),
                                }}
                            />
                        </Typography>

                        <Button
                            onClick={() => toggleCalculate()}
                            message={t('calculate.title')}
                            active
                            gtmId="home-energy-calculator"
                        />
                    </Grid>
                </Grid>
            </Container>
        </section>
    );
};

Solution4energy.defaultProps = {
    toggleCalculate: () => null,
};

Solution4energy.propTypes = {
    toggleCalculate: PropTypes.func,
};

export default Solution4energy;
