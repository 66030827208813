import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import i18n, { getLanguage } from '../libs/i18n';

class AppContainer extends Component {
    constructor(props) {
        super();
        const {
            match: { params },
        } = props;
        if (params.language !== getLanguage()) {
            i18n.changeLanguage(params.language);
        }
    }

    render() {
        const {
            children,
            match: { params },
        } = this.props;
        if (params.language !== getLanguage()) {
            return null;
        }
        return <>{children}</>;
    }
}

AppContainer.defaultProps = {
    children: <span />,
};

AppContainer.propTypes = {
    children: PropTypes.node,
    match: PropTypes.object.isRequired,
};

export default withRouter(AppContainer);
