import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function WorldSvg(props) {
    const finalProps = {
        ...props,
        viewBox: '0 0 35 35',
    };
    return (
        <SvgIcon {...finalProps}>
            <g>
                <path d="M17,2A15,15,0,1,0,32,17,15.005,15.005,0,0,0,17,2ZM15.5,28.9A11.983,11.983,0,0,1,5,17a12.177,12.177,0,0,1,.315-2.685L12.5,21.5V23a3.009,3.009,0,0,0,3,3Zm10.35-3.81A2.976,2.976,0,0,0,23,23H21.5V18.5A1.5,1.5,0,0,0,20,17H11V14h3a1.5,1.5,0,0,0,1.5-1.5v-3h3a3.009,3.009,0,0,0,3-3V5.885a11.973,11.973,0,0,1,4.35,19.2Z" />
            </g>
        </SvgIcon>
    );
}

export default WorldSvg;
