import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
        fontFamily: 'facto',
    },
    textColor: '#333',
    palette: {
        primary: {
            main: '#00CF55',
            light: 'rgba(0, 207, 85, 0.5)',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main: '#50D2AA',
            hover: 'rgba(80, 210, 170, 0.8)',
        },
        tertiary: {
            main: '#1EA5E6',
        },
        quaternary: {
            main: '#04AAA1',
        },
        quinary: {
            main: '#80A1B2',
        },
        senary: {
            main: '#16206D',
            inactive: 'rgba(22, 32, 109, 0.75)',
        },
        gray: {
            main: '#666',
        },
        ligthGray: {
            main: '#F7F7F7',
            border: 'rgba(0, 0, 0, 0.12)',
            background: 'rgba(0, 0, 0, 0.012)',
        },
        error: {
            main: 'rgb(215, 25, 28)',
            background: 'rgba(215, 25, 28, 0.2)',
            backgroundHard: 'rgba(215, 25, 28, 0.7)',
        },
        success: {
            main: 'rgb(76, 175, 80)',
            background: 'rgba(76, 175, 80, 0.1)',
        },
    },
    transition: (properties = ['all']) =>
        properties.map((p) => `${p} 0.5s ease`).join(', '),
});

export default theme;
