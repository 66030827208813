import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useParams, useHistory } from 'react-router-dom';
import classNames from 'classnames';

import i18n from '../../../libs/i18n';
import WorldIcon from '../../../components/icons/world';

const styles = (theme) => {
    return {
        root: {
            textDecoration: 'none',
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
        },
        container: {
            height: 36,
            width: 36,
            position: 'relative',
            overflow: 'hidden',
        },
        containerSmall: {
            height: 25,
            width: 25,
        },
        containerActive: {
            overflow: 'visible',
        },
        icon: {
            fill: 'white',
            opacity: '0.352',
            width: 36,
            height: 36,
            cursor: 'pointer',
            transition: theme.transition(['opacity']),
            '&:hover': {
                opacity: '1',
            },
        },
        iconSmall: {
            height: 25,
            width: 25,
        },
        iconActive: {
            opacity: '1',
        },
        buttonContainer: {
            position: 'absolute',
            top: 50,
            left: -3,
            zIndex: 10,
            backgroundColor: theme.palette.secondary.main,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 5,
            padding: theme.spacing(1, 0),
            opacity: 0,
            transition: theme.transition(['opacity']),
        },
        buttonContainerSmall: {
            top: 37,
            left: -8,
        },
        buttonContainerActive: {
            opacity: 1,
        },
        triangleUp: {
            display: 'block',
            position: 'absolute',
            top: -9,
            left: '50%',
            marginLeft: -10,
            width: 0,
            height: 0,
            borderLeft: '10px solid transparent',
            borderRight: '10px solid transparent',
            borderBottom: `10px solid ${theme.palette.secondary.main}`,
        },
        button: {
            minWidth: 40,
            '&:hover': {
                color: 'white',
            },
        },
        active: {
            color: 'white',
        },
    };
};

const useStyles = makeStyles((theme) => styles(theme));

const LanguageSelector = ({ size }) => {
    const classes = useStyles();
    const history = useHistory();
    const { section, language } = useParams();
    const [open, setOpen] = useState(false);

    const handleChangeLang = (lang) => {
        i18n.changeLanguage(lang);
        history.replace(`/${lang}/${section}`);
    };
    return (
        <div className={classes.root} onMouseLeave={() => setOpen(false)}>
            <div
                className={classNames(
                    classes.container,
                    open && classes.containerActive,
                    size === 'small' && classes.containerSmall,
                )}
                onMouseEnter={() => setOpen(true)}
            >
                <WorldIcon
                    className={classNames(
                        classes.icon,
                        open && classes.iconActive,
                        size === 'small' && classes.iconSmall,
                    )}
                />
                <div
                    className={classNames(
                        classes.buttonContainer,
                        open && classes.buttonContainerActive,
                        size === 'small' && classes.buttonContainerSmall,
                    )}
                >
                    <span className={classes.triangleUp} />
                    <Button
                        className={classNames(
                            classes.button,
                            language === 'fr' && classes.active,
                        )}
                        onClick={() => handleChangeLang('fr')}
                    >
                        fr
                    </Button>
                    <Button
                        className={classNames(
                            classes.button,
                            language === 'nl' && classes.active,
                        )}
                        onClick={() => handleChangeLang('nl')}
                    >
                        nl
                    </Button>
                </div>
            </div>
        </div>
    );
};

LanguageSelector.defaultProps = {
    size: 'small',
};

LanguageSelector.propTypes = {
    size: PropTypes.string,
};

export default LanguageSelector;
