import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import classNames from 'classnames';

const styles = (theme) => {
    return {
        root: {
            width: '100%',
            height: '100%',
            padding: '2vh 0',
            [theme.breakpoints.down('md')]: {
                padding: '10vh 0',
            },
            [theme.breakpoints.down('xs')]: {
                padding: '12vh 0 4vh 0',
            },
        },
        container: {
            height: '100%',
            width: '100%',
            padding: 0,
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.down('md')]: {
                padding: theme.spacing(0, 4),
            },
            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(0, 2),
            },
        },
        textContainer: {
            textAlign: 'center',
            display: 'flex',
            height: '70%',
            width: '100%',
            justifyContent: 'space-between',
            flexDirection: 'column',
            [theme.breakpoints.down('xs')]: {
                height: '94%',
            },
        },
        title: {
            fontSize: 42,
            fontWeight: 500,
            color: theme.palette.primary.main,
            [theme.breakpoints.down('md')]: {
                fontSize: 30,
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: 26,
                marginBottom: theme.spacing(3),
            },
        },
        desc: {
            fontSize: 18,
            lineHeight: '30px',
            [theme.breakpoints.down('md')]: {
                fontSize: 16,
                lineHeight: '18px',
            },
            [theme.breakpoints.down('xs')]: {
                marginBottom: theme.spacing(3),
            },
        },
        featuresList: {
            listStyle: 'none',
            display: 'flex',
            padding: 0,
            margin: 0,
            [theme.breakpoints.down('xs')]: {
                display: 'block',
            },
        },
        featureContainer: {
            padding: theme.spacing(0, 2),
            width: 'calc(100% / 3)',
            transition: theme.transition(['all']),
            alignSelf: 'stretch',
            userSelect: 'none',
            '-webkit-tap-highlight-color': 'transparent',
            '&:hover': {
                transform: 'scale(1.05)',
            },
            '&:hover > div': {
                backgroundColor: theme.palette.senary.main,
            },
            [theme.breakpoints.down('md')]: {
                padding: theme.spacing(0, 4),
            },
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(0, 1),
            },
            [theme.breakpoints.down('xs')]: {
                width: '100%',
                marginBottom: theme.spacing(2),
                '& > div': {
                    backgroundColor: theme.palette.senary.main,
                },
            },
        },
        featureContainerActive: {
            transform: 'scale(1.05)',
            '& > div': {
                backgroundColor: theme.palette.senary.main,
            },
        },
        feature: {
            borderRadius: 10,
            backgroundColor: theme.palette.senary.inactive,
            color: 'white',
            padding: '40px 45px',
            cursor: 'pointer',
            transition: theme.transition(['background-color']),
            height: '100%',
            [theme.breakpoints.down('md')]: {
                padding: '20px 28px',
            },
        },
        subTitle: {
            fontSize: 22,
            marginBottom: theme.spacing(3),
            [theme.breakpoints.down('md')]: {
                fontSize: 18,
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: 16,
                marginBottom: theme.spacing(1),
            },
        },
        subDesc: {
            fontSize: 16,
            [theme.breakpoints.down('md')]: {
                fontSize: 13,
            },
        },
    };
};

const useStyles = makeStyles((theme) => styles(theme));

const Solutions = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [firstEnabled, setFirstEnabled] = useState(true);

    return (
        <section className={classes.root}>
            <Container className={classes.container} maxWidth="lg">
                <div className={classes.textContainer}>
                    <Typography className={classes.title} variant="h2">
                        {t('solutions.title')}
                    </Typography>
                    <Container maxWidth="md">
                        <Typography className={classes.desc}>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: t('solutions.description'),
                                }}
                            />
                        </Typography>
                    </Container>
                    <ul className={classes.featuresList}>
                        <li
                            onMouseLeave={() => setFirstEnabled(false)}
                            className={classNames(
                                classes.featureContainer,
                                firstEnabled && classes.featureContainerActive,
                            )}
                        >
                            <div className={classes.feature}>
                                <Typography
                                    className={classes.subTitle}
                                    variant="h3"
                                >
                                    {t('solutions.featureOne.title')}
                                </Typography>
                                <Typography className={classes.subDesc}>
                                    {t('solutions.featureOne.description')}
                                </Typography>
                            </div>
                        </li>
                        <li className={classes.featureContainer}>
                            <div className={classes.feature}>
                                <Typography
                                    className={classes.subTitle}
                                    variant="h3"
                                >
                                    {t('solutions.featureTwo.title')}
                                </Typography>
                                <Typography className={classes.subDesc}>
                                    {t('solutions.featureTwo.description')}
                                </Typography>
                            </div>
                        </li>
                        <li className={classes.featureContainer}>
                            <div className={classes.feature}>
                                <Typography
                                    className={classes.subTitle}
                                    variant="h3"
                                >
                                    {t('solutions.featureThree.title')}
                                </Typography>
                                <Typography className={classes.subDesc}>
                                    {t('solutions.featureThree.description')}
                                </Typography>
                            </div>
                        </li>
                    </ul>
                </div>
            </Container>
        </section>
    );
};

export default Solutions;
