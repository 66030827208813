import React from 'react';
import { I18nextProvider } from 'react-i18next';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from 'react-router-dom';
import i18n, { getLanguage } from './libs/i18n';
import Polyfills from './libs/polyfills';

import Home from './pages/home/index';
import AppContainer from './pages/AppContainer';

Polyfills.init();
const App = () => {
    return (
        <I18nextProvider i18n={i18n}>
            <Router>
                <Switch>
                    <Route exact path="/">
                        <Redirect to={`/${getLanguage()}/home`} />
                    </Route>
                    <Route exact path="/fr">
                        <Redirect to="/fr/home" />
                    </Route>
                    <Route exact path="/nl">
                        <Redirect to="/nl/home" />
                    </Route>
                    ,
                    <Route path="/:language/:section">
                        <AppContainer>
                            <Home />
                        </AppContainer>
                    </Route>
                </Switch>
            </Router>
        </I18nextProvider>
    );
};

export default App;
