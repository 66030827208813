import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';

import Button from '../../../components/buttons';

import halfLogo from '../../../img/png/halfLogo.png';
import heroIcons from '../../../img/svg/heroIcons.svg';

import { navigate } from '../../../utils';

const styles = (theme) => {
    return {
        root: {
            width: '100%',
            height: '100%',
            backgroundImage: `url(${halfLogo})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '0 0',
            backgroundSize: 'auto 100vh',
            [theme.breakpoints.down('md')]: {
                backgroundSize: 'auto 85vh',
            },
            [theme.breakpoints.down('sm')]: {
                backgroundSize: 'auto 65vh',
            },
            [theme.breakpoints.down('xs')]: {
                backgroundSize: 'auto 40vh',
                height: '100vh',
            },
        },
        container: {
            height: '100%',
            width: '100%',
            padding: 0,
        },
        iconsContainer: {
            height: '100%',
            width: '100%',
            backgroundImage: `url(${heroIcons})`,
            backgroundRepeat: 'no-repeat',
            backgroundPositionX: 'right',
            backgroundPositionY: '30vh',
            backgroundSize: 'auto 55vh',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            paddingTop: '41vh',
            [theme.breakpoints.down('md')]: {
                backgroundSize: 'auto 50vh',
                backgroundPositionX: '14vw',
                backgroundPositionY: '36vh',
            },
            [theme.breakpoints.down('sm')]: {
                backgroundSize: 'auto 40vh',
                backgroundPositionX: '14vw',
                backgroundPositionY: '36vh',
            },
            [theme.breakpoints.down('xs')]: {
                paddingTop: 0,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundSize: 'auto 54vh',
                backgroundPositionX: '12vw',
            },
        },
        textContainer: {
            color: 'white',
            textAlign: 'center',
            width: '40%',
            marginRight: '10%',
            [theme.breakpoints.down('xs')]: {
                width: '100%',
                margin: 0,
                padding: theme.spacing(0, 2),
            },
        },
        title: {
            fontSize: 42,
            fontWeight: 700,
            marginBottom: theme.spacing(4),
            [theme.breakpoints.down('sm')]: {
                fontSize: 30,
            },
        },
        desc: {
            fontSize: 24,
            marginBottom: theme.spacing(8),
            [theme.breakpoints.down('sm')]: {
                fontSize: 18,
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: 16,
                marginBottom: theme.spacing(5),
            },
        },
    };
};

const useStyles = makeStyles((theme) => styles(theme));

const Hero = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { language } = useParams();
    const history = useHistory();
    const { setSpyEnabled } = props;
    const handleClick = () => {
        navigate(setSpyEnabled, language, 'solutions', history.push);
    };
    return (
        <section className={classes.root}>
            <Container className={classes.container} maxWidth="lg">
                <div className={classes.iconsContainer}>
                    <div className={classes.textContainer}>
                        <Typography className={classes.title} variant="h1">
                            {t('hero.title')}
                        </Typography>
                        <Typography className={classes.desc}>
                            {t('hero.description')}
                        </Typography>
                        <Button
                            onClick={() => handleClick()}
                            message={t('hero.button')}
                            active
                            gtmId="home-hero-read-more"
                        />
                    </div>
                </div>
            </Container>
        </section>
    );
};
Hero.defaultProps = {
    setSpyEnabled: () => null,
};

Hero.propTypes = {
    setSpyEnabled: PropTypes.func,
};

export default Hero;
