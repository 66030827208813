import theme from '../theme';

const buttons = {
    root: {
        textDecoration: 'none',
        color: 'white',
        padding: '10px 16px 13px 16px',
        display: 'inline-block',
        borderRadius: 35,
        transition: theme.transition(['background-color']),
        '&:hover': {
            backgroundColor: theme.palette.secondary.hover,
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 16,
            padding: '8px 16px 10px 16px',
        },
    },
    active: {
        backgroundColor: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: theme.palette.secondary.hover,
        },
    },
    disabled: {
        backgroundColor: theme.palette.ligthGray.main,
    },
    fullWidth: {
        width: '100%',
    },
    large: {
        padding: '15px 16px 18px 16px',
        [theme.breakpoints.down('xs')]: {
            fontSize: 13,
            padding: '10px 11px 12px 10px',
        },
    },
    label: {
        textTransform: 'none',
        fontSize: 16,
        lineHeight: '16px',
        [theme.breakpoints.down('xs')]: {
            fontSize: 16,
            lineHeight: '15px',
        },
    },
};

export default buttons;
