import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
    FormControl,
    InputLabel,
    OutlinedInput,
    Select,
    Tooltip,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import Info from '@material-ui/icons/Info';
import moment from 'moment';
import orderBy from 'lodash/orderBy';
import EuroIcon from '@material-ui/icons/Euro';

import commonStyles from '../styles/index';
import TextMaskCustom from './TextMaskCustom';

const styles = () => {
    return {
        ...commonStyles.forms,
    };
};

const useStyles = makeStyles(() => styles());

export default function Input({
    handleChangeForm,
    handleBlur,
    item: { error, blur, value, key, required, label },
    type,
    options,
    other,
    info,
    format,
    prefix,
}) {
    const { t } = useTranslation();
    const classes = useStyles();

    const orderedOptions = orderBy(options, [(o) => o.label], ['asc']);
    if (other) {
        orderedOptions.push({
            value: 'other',
            label: t('calculate.other'),
        });
    }

    const handleChangeNumber = (newKey, newValue) => {
        if (parseFloat(newValue) >= 0 || newValue === '') {
            handleChangeForm(newKey, newValue);
        }
    };

    const inputExtraProps =
        format !== ''
            ? {
                  inputComponent: TextMaskCustom,
              }
            : {};

    return (
        <FormControl variant="outlined" className={classes.formControl}>
            {type === 'input' && (
                <>
                    <InputLabel
                        error={error && blur}
                        htmlFor={key}
                        className={classes.label}
                    >
                        {label}
                        {required && <span>{' *'}</span>}
                    </InputLabel>
                    <OutlinedInput
                        inputProps={{
                            className: info !== '' ? classes.inputPadded : null,
                            format,
                            prefix,
                            name: key,
                        }}
                        required={required}
                        id={key}
                        value={value}
                        error={error && blur}
                        onBlur={() => handleBlur(key)}
                        onChange={(e) => handleChangeForm(key, e.target.value)}
                        label={label}
                        {...inputExtraProps}
                    />
                </>
            )}
            {type === 'money' && (
                <div className={classes.moneyContainer}>
                    <InputLabel
                        error={error && blur}
                        htmlFor={key}
                        className={classes.label}
                    >
                        {label}
                        {required && <span>{' *'}</span>}
                    </InputLabel>
                    <OutlinedInput
                        required={required}
                        id={key}
                        type="number"
                        value={value}
                        inputProps={{
                            className: classes.moneyInput,
                        }}
                        error={error && blur}
                        onBlur={() => handleBlur(key)}
                        onChange={(e) =>
                            handleChangeNumber(key, e.target.value)
                        }
                        label={label}
                    />
                    <EuroIcon
                        fontSize="small"
                        className={classes.moneySymbol}
                    />
                </div>
            )}
            {type === 'number' && (
                <>
                    <InputLabel
                        error={error && blur}
                        htmlFor={key}
                        className={classes.label}
                    >
                        {label}
                        {required && <span>{' *'}</span>}
                    </InputLabel>
                    <OutlinedInput
                        required={required}
                        id={key}
                        type="number"
                        value={value}
                        inputProps={{
                            className: classes.moneyInput,
                            min: 0,
                        }}
                        error={error && blur}
                        onBlur={() => handleBlur(key)}
                        onChange={(e) =>
                            handleChangeNumber(key, e.target.value)
                        }
                        label={label}
                    />
                </>
            )}
            {type === 'date' && (
                <DatePicker
                    autoOk
                    error={error && blur}
                    disableToolbar
                    InputLabelProps={{
                        className: classes.label,
                        shrink: value !== '',
                    }}
                    id={key}
                    variant="inline"
                    inputVariant="outlined"
                    format="DD/MM/yyyy"
                    label={`${label}${required && ' *'}`}
                    value={value === '' ? null : value}
                    onChange={(newValue) =>
                        handleChangeForm(
                            key,
                            moment(newValue).format('yyyy/MM/DD'),
                        )
                    }
                    onBlur={() => handleBlur(key)}
                />
            )}
            {type === 'select' && (
                <>
                    <InputLabel
                        error={error && blur}
                        htmlFor={key}
                        className={classes.label}
                    >
                        {label}
                        {required && <span>{' *'}</span>}
                    </InputLabel>
                    <Select
                        onBlur={() => handleBlur(key)}
                        id={key}
                        error={error && blur}
                        native
                        value={value}
                        onChange={(e) => handleChangeForm(key, e.target.value)}
                        label={label}
                        inputProps={{
                            name: key,
                            id: key,
                        }}
                    >
                        <option aria-label="None" value="" />
                        {orderedOptions.map((o) => (
                            <option key={o.value} value={o.value}>
                                {o.label}
                            </option>
                        ))}
                    </Select>
                </>
            )}
            {info !== '' && (
                <div className={classes.infos}>
                    <Tooltip title={info} arrow enterTouchDelay={0}>
                        <Info className={classes.infosIcon} color="primary" />
                    </Tooltip>
                </div>
            )}
        </FormControl>
    );
}

Input.defaultProps = {
    item: {
        key: '',
        value: '',
        error: false,
        blur: false,
        required: false,
        label: '',
    },
    type: 'input',
    options: [],
    other: false,
    info: '',
    format: '',
    prefix: '',
};

Input.propTypes = {
    handleChangeForm: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    item: PropTypes.object,
    type: PropTypes.string,
    options: PropTypes.array,
    other: PropTypes.bool,
    info: PropTypes.string,
    format: PropTypes.string,
    prefix: PropTypes.string,
};
