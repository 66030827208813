import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import FullHeightSection from '../../components/layout/FullHeightSection';
import NavContainer from './components/NavContainer';
import Footer from './components/Footer';
import CookieBanner from './components/CookieBanner';
import Calculate from './components/Calculate';
import { scrollToSection } from '../../utils';
import { sections } from './config';

const styles = () => {
    return {
        root: {
            backgroundColor: 'white',
        },
    };
};

class Home extends Component {
    constructor() {
        super();
        this.state = {
            spyEnabled: false,
            isVisible: true,
            lastScroll: 0,
            calculateOpen: false,
        };
    }

    componentDidMount() {
        const {
            match: { params },
            t,
        } = this.props;
        let timer = 0;
        if (params.section) {
            scrollToSection(params.section);
            timer = 1000;
            if (params.section === t('calculate.link')) {
                this.setCalculateOpen(true);
            }
        }
        window.addEventListener('scroll', () => this.handleScroll());
        setTimeout(() => {
            this.setSpyEnabled(true);
        }, timer);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', () => this.handleScroll());
    }

    setSpyEnabled(spyEnabled) {
        this.setState({
            spyEnabled,
        });
    }

    setCalculateOpen(calculateOpen) {
        this.setState({
            calculateOpen,
        });
    }

    handleScroll() {
        const { lastScroll } = this.state;
        const currentScroll = window.scrollY;
        this.setState({
            lastScroll: currentScroll,
            isVisible: currentScroll < lastScroll,
        });
    }

    render() {
        const { t, classes } = this.props;
        const { spyEnabled, isVisible, lastScroll, calculateOpen } = this.state;
        return (
            <div className={classes.root}>
                <NavContainer
                    setSpyEnabled={(enabled) => this.setSpyEnabled(enabled)}
                    isVisible={isVisible}
                    lastScroll={lastScroll}
                />
                <Calculate
                    open={calculateOpen}
                    setOpen={(open) => this.setCalculateOpen(open)}
                />
                {sections(
                    t,
                    (enabled) => this.setSpyEnabled(enabled),
                    (open) => this.setCalculateOpen(open),
                ).map((s) => (
                    <FullHeightSection
                        spyEnabled={spyEnabled}
                        setSpyEnabled={(enabled) => this.setSpyEnabled(enabled)}
                        key={s.key}
                        colors={s.colors}
                        gradiantOrientation={s.gradiantOrientation}
                        sectionKey={s.key}
                    >
                        {s.component}
                    </FullHeightSection>
                ))}
                <Footer />
                <CookieBanner />
            </div>
        );
    }
}

Home.propTypes = {
    t: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(withTranslation()(Home)));
