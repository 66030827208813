export const validateEmail = (mail) => {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        return true;
    }
    return false;
};

export const getFormErrors = (form, flat = false) => {
    const newErrors = {};
    Object.keys(form).forEach((fKey) => {
        if (fKey === 'mail' || fKey === 'email') {
            newErrors[fKey] = !validateEmail(form[fKey]);
        } else if (fKey === 'vat_number') {
            newErrors[fKey] = form[fKey] === '' || form[fKey].length < 10;
        } else if (fKey === 'mail' || fKey === 'email') {
            newErrors[fKey] = !validateEmail(form[fKey]);
        } else if (Array.isArray(form[fKey])) {
            if (!newErrors[fKey]) {
                newErrors[fKey] = [];
            }
            form[fKey].forEach((ai, index) => {
                Object.keys(ai).forEach((aiKey) => {
                    if (!flat) {
                        newErrors[fKey][index] = {
                            ...newErrors[fKey][index],
                            [aiKey]: ai[aiKey] === '',
                        };
                    } else {
                        newErrors[`${fKey}-${index}-${aiKey}`] =
                            ai[aiKey] === '';
                    }
                    // newErrors[fKey][index][aiKey] = ai[aiKey] === '';
                });
            });
        } else {
            newErrors[fKey] = form[fKey] === '';
        }
    });
    return newErrors;
};

export const getFormInitialBlur = (form) => {
    const bluredItems = {};
    Object.keys(form).forEach((fKey) => {
        if (Array.isArray(form[fKey])) {
            if (!bluredItems[fKey]) {
                bluredItems[fKey] = [];
            }
            form[fKey].forEach((ai, index) => {
                Object.keys(ai).forEach((aiKey) => {
                    bluredItems[fKey][index] = {
                        ...bluredItems[fKey][index],
                        [aiKey]: false,
                    };
                });
            });
        } else {
            bluredItems[fKey] = false;
        }
    });
    return bluredItems;
};

/**
 * Get the input correct props.
 * @param {String} key // the input key used to identify the values
 * @param {Boolean} required
 * @param {String} label // displayed label
 * @param {Function} handleBlur
 * @param {Function} handleChangeForm
 * @param {Object} form // the form object with values, errors and bluredItems
 */

export const getInputProps = (
    key,
    required,
    label,
    handleBlur,
    handleChangeForm,
    form,
) => ({
    item: {
        key,
        value: form.values[key],
        required,
        label,
        error: required ? form.errors[key] : undefined,
        blur: form.bluredItems[key],
    },
    handleBlur: () => handleBlur(key),
    handleChangeForm: (theKey, value) => handleChangeForm(key, value),
});
