import theme from '../theme';

const forms = {
    root: {
        '& .MuiOutlinedInput-root': {
            width: '100%',
            '& input[type=number]': {
                '-moz-appearance': 'textfield',
            },
            '& input': {
                padding: '12px 16px',
                height: 15,
            },
            '& fieldset': {
                borderColor: theme.palette.primary.light,
            },
            '&:hover fieldset': {
                borderColor: theme.palette.primary.light,
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.primary.light,
            },
            '&.Mui-error.Mui-focused fieldset': {
                borderColor: theme.palette.error.main,
            },
            '&:hover.Mui-error fieldset': {
                borderColor: theme.palette.error.main,
            },
        },
        '& .MuiSelect-select:focus': {
            backgroundColor: 'transparent',
        },
        '& .MuiSelect-select': {
            height: 19,
            padding: '10px 16px',
        },
        [theme.breakpoints.down('xs')]: {
            padding: '12vh 0 0 0 ',
        },
    },
    relativeContainer: {
        position: 'relative',
    },
    noPadding: {
        padding: '0 ! important',
    },
    formContainer: {
        border: `1px solid ${theme.palette.primary.light}`,
        borderRadius: '10px',
        position: 'relative',
        padding: theme.spacing(3, 8),
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(3),
            fontSize: '13px !important',
            '& label span': {
                fontSize: 13,
            },
        },
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(2),
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(6),
            fontSize: '11px !important',
            '& label span': {
                fontSize: '11px !important',
            },
        },
    },
    formControl: {
        width: '100%',
        position: 'relative',
    },
    label: {
        color: 'black',
        backgroundColor: 'white',
        paddingRight: theme.spacing(1),
        transform: 'translate(14px, 11px) scale(1)',
    },
    checkBox: {
        color: theme.palette.primary.light,
    },
    info: {
        fontSize: 12,
        lineHeight: '18px',
        fontWeight: 'lighter',
        paddingRight: theme.spacing(9),
        [theme.breakpoints.down('md')]: {
            paddingRight: 0,
            fontSize: 11,
        },
        [theme.breakpoints.down('xs')]: {
            paddingRight: 0,
            fontSize: 8,
            lineHeight: '10px',
        },
    },
    successContainer: {
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        borderRadius: '10px',
        alignItems: 'center',
        color: theme.palette.success.main,
        zIndex: 10,
        flexDirection: 'column',
    },
    successIcon: {
        marginBottom: theme.spacing(2),
    },
    warningIcon: {
        marginRight: theme.spacing(1),
        position: 'relative',
        top: 5,
    },
    loadingContainer: {
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        position: 'absolute',
        borderRadius: '10px',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 10,
    },
    errorContainer: {
        color: theme.palette.error.main,
        fontSize: 14,
    },
    formRow: {
        [theme.breakpoints.down('sm')]: {
            padding: '16px !important',
        },
    },
    formTrick: {
        [theme.breakpoints.down('sm')]: {
            paddingBottom: '0 !important',
        },
    },
    moneyContainer: {
        position: 'relative',
    },
    moneySymbol: {
        position: 'absolute',
        right: theme.spacing(2),
        top: 10,
    },
    moneyInput: {
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
    },
    infos: {
        position: 'absolute',
        right: theme.spacing(2),
        top: 8,
    },
    inputPadded: {
        paddingRight: `40px ! important`,
    },
    inlineRadio: {
        display: 'flex',
        flexDirection: 'row',
    },
    multiFieldActions: {
        [theme.breakpoints.down('xs')]: {
            position: 'absolute',
            right: theme.spacing(1),
            top: 0,
            display: 'flex',
            height: '100%',
        },
    },
    noPaddingMobile: {
        [theme.breakpoints.down('xs')]: {
            padding: '0 !important',
        },
    },
    paddingTopMobile: {
        [theme.breakpoints.down('xs')]: {
            paddingTop: `${theme.spacing(2)}px ! important`,
        },
    },
    noPaddingTopMobile: {
        [theme.breakpoints.down('xs')]: {
            paddingTop: '0 !important',
        },
    },
};
export default forms;
