import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import classNames from 'classnames';

import clover from '../../../img/svg/clover.svg';
import cloverBg from '../../../img/png/cloverBg.png';

const styles = (theme) => {
    return {
        root: {
            width: '100%',
            height: '100%',
            padding: '6vh 0',
            backgroundImage: `url(${cloverBg})`,
            backgroundRepeat: 'no-repeat',
            backgroundPositionX: 'right',
            backgroundPositionY: '0',
            backgroundSize: 'auto 90vh',
            [theme.breakpoints.down('md')]: {
                padding: '4vh 0',
            },
            [theme.breakpoints.down('sm')]: {
                padding: '8vh 0',
            },
            [theme.breakpoints.down('xs')]: {
                padding: '11vh 0 8vh 0',
                background: 'none',
            },
        },
        container: {
            height: '100%',
            width: '100%',
            padding: 0,
            display: 'flex',
            alignItems: 'center',
        },
        textContainer: {
            textAlign: 'center',
            display: 'flex',
            height: '80%',
            width: '100%',
            justifyContent: 'space-between',
            flexDirection: 'column',
            [theme.breakpoints.down('xs')]: {
                height: '100%',
            },
        },
        title: {
            fontSize: 42,
            fontWeight: 500,
            color: theme.palette.primary.main,
            [theme.breakpoints.down('md')]: {
                fontSize: 30,
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: 26,
                marginBottom: theme.spacing(3),
            },
        },
        featuresList: {
            listStyle: 'none',
            display: 'flex',
            padding: 0,
            margin: 0,
            [theme.breakpoints.down('xs')]: {
                display: 'block',
            },
        },
        featuresContainer: {
            position: 'relative',
        },
        cloverContainer: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                '& img': {
                    width: '25%',
                    height: 'auto',
                },
            },
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
        },
        featureContainer: {
            display: 'flex',
            padding: theme.spacing(0, 2),
            width: '50%',
            cursor: 'pointer',
            zIndex: 10,
            transition: theme.transition(['all']),
            userSelect: 'none',
            '-webkit-tap-highlight-color': 'transparent',
            '&:hover > div': {
                color: 'white',
            },
            [theme.breakpoints.down('md')]: {
                padding: theme.spacing(0, 4),
            },
            [theme.breakpoints.down('xs')]: {
                width: '100%',
            },
        },
        featureOne: {
            '&:hover > div': {
                backgroundColor: theme.palette.tertiary.main,
            },
            [theme.breakpoints.down('xs')]: {
                '& > div': {
                    backgroundColor: theme.palette.tertiary.main,
                },
            },
        },
        featureTwo: {
            '&:hover > div': {
                backgroundColor: theme.palette.secondary.main,
            },
            [theme.breakpoints.down('xs')]: {
                '& > div': {
                    backgroundColor: theme.palette.secondary.main,
                },
            },
        },
        featureThree: {
            '&:hover > div': {
                backgroundColor: theme.palette.primary.main,
            },
            [theme.breakpoints.down('xs')]: {
                '& > div': {
                    backgroundColor: theme.palette.primary.main,
                },
            },
        },
        featureFour: {
            '&:hover > div': {
                backgroundColor: theme.palette.quaternary.main,
            },
            [theme.breakpoints.down('xs')]: {
                '& > div': {
                    backgroundColor: theme.palette.quaternary.main,
                },
            },
        },
        secondFeatureContainer: {
            justifyContent: 'flex-end',
        },
        feature: {
            borderRadius: 10,
            width: '65%',
            color: 'black',
            padding: '20px 35px',
            cursor: 'pointer',
            transition: theme.transition(['background-color', 'color']),
            textAlign: 'left',
            marginBottom: theme.spacing(2),
            [theme.breakpoints.down('sm')]: {
                width: '75%',
            },
            [theme.breakpoints.down('xs')]: {
                width: '100%',
                padding: '20px 28px',
                marginBottom: theme.spacing(2),
            },
        },
        subTitle: {
            fontSize: 22,
            marginBottom: theme.spacing(3),
            [theme.breakpoints.down('md')]: {
                fontSize: 18,
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: 16,
                marginBottom: theme.spacing(1),
            },
        },
        subDesc: {
            fontSize: 16,
            [theme.breakpoints.down('md')]: {
                fontSize: 13,
            },
        },
    };
};

const useStyles = makeStyles((theme) => styles(theme));

const Benefits = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    return (
        <section className={classes.root}>
            <Container className={classes.container} maxWidth="lg">
                <div className={classes.textContainer}>
                    <Typography className={classes.title} variant="h2">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: t('benefits.title'),
                            }}
                        />
                    </Typography>
                    <div className={classes.featuresContainer}>
                        <div className={classes.cloverContainer}>
                            <img alt="clover" src={clover} />
                        </div>
                        <ul className={classes.featuresList}>
                            <li
                                className={classNames(
                                    classes.featureContainer,
                                    classes.featureOne,
                                )}
                            >
                                <div className={classes.feature}>
                                    <Typography
                                        className={classes.subTitle}
                                        variant="h3"
                                    >
                                        {t('benefits.featureOne.title')}
                                    </Typography>
                                    <Typography className={classes.subDesc}>
                                        {t('benefits.featureOne.description')}
                                    </Typography>
                                </div>
                            </li>
                            <li
                                className={classNames(
                                    classes.featureContainer,
                                    classes.secondFeatureContainer,
                                    classes.featureThree,
                                )}
                            >
                                <div className={classes.feature}>
                                    <Typography
                                        className={classes.subTitle}
                                        variant="h3"
                                    >
                                        {t('benefits.featureThree.title')}
                                    </Typography>
                                    <Typography className={classes.subDesc}>
                                        {t('benefits.featureThree.description')}
                                    </Typography>
                                </div>
                            </li>
                        </ul>
                        <ul className={classes.featuresList}>
                            <li
                                className={classNames(
                                    classes.featureContainer,
                                    classes.featureTwo,
                                )}
                            >
                                <div className={classes.feature}>
                                    <Typography
                                        className={classes.subTitle}
                                        variant="h3"
                                    >
                                        {t('benefits.featureTwo.title')}
                                    </Typography>
                                    <Typography className={classes.subDesc}>
                                        {t('benefits.featureTwo.description')}
                                    </Typography>
                                </div>
                            </li>
                            <li
                                className={classNames(
                                    classes.featureContainer,
                                    classes.secondFeatureContainer,
                                    classes.featureFour,
                                )}
                            >
                                <div className={classes.feature}>
                                    <Typography
                                        className={classes.subTitle}
                                        variant="h3"
                                    >
                                        {t('benefits.featureFour.title')}
                                    </Typography>
                                    <Typography className={classes.subDesc}>
                                        {t('benefits.featureFour.description')}
                                    </Typography>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </Container>
        </section>
    );
};

export default Benefits;
