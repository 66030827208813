import React from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { InView } from 'react-intersection-observer';

import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => {
    // const styles = (theme) => {
    return {
        root: {
            height: '100vh',
            width: '100%',
            [theme.breakpoints.down('xs')]: {
                height: 'auto',
                minHeight: '100vh',
            },
        },
    };
};

const useStyles = makeStyles((theme) => styles(theme));

const FullHeightSection = (props) => {
    const { language, section } = useParams();
    const history = useHistory();
    const {
        sectionKey,
        color,
        colors,
        gradiantOrientation,
        children,
        baseUrl,
        spyEnabled,
    } = props;
    const classes = useStyles(props);

    const handleChangeView = (newSectionKey, inView) => {
        if (inView && newSectionKey !== section && spyEnabled) {
            history.replace(`${baseUrl}${language}/${newSectionKey}`);
        }
    };

    let background = color;
    if (gradiantOrientation !== 'none' && colors.length === 2) {
        if (gradiantOrientation === 'horizontal') {
            background = `linear-gradient(to left, ${colors[1]}, ${colors[0]})`;
        } else {
            background = `linear-gradient(${colors[0]}, ${colors[1]})`;
        }
    }
    return (
        <InView
            threshold={0.6}
            onChange={(inView) => handleChangeView(sectionKey, inView)}
        >
            <div
                className={classes.root}
                id={`section-${sectionKey}`}
                style={{
                    background,
                }}
            >
                {children}
            </div>
        </InView>
    );
};

FullHeightSection.defaultProps = {
    color: 'transparent',
    colors: [],
    gradiantOrientation: 'none',
    baseUrl: '/',
    children: <span />,
};

FullHeightSection.propTypes = {
    sectionKey: PropTypes.string.isRequired,
    color: PropTypes.string,
    colors: PropTypes.array,
    gradiantOrientation: PropTypes.string,
    baseUrl: PropTypes.string,
    children: PropTypes.node,
    spyEnabled: PropTypes.bool.isRequired,
};

export default FullHeightSection;
