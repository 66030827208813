import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
    Button,
    Box,
    Tooltip,
    IconButton,
    FormControlLabel,
    Checkbox,
    FormLabel,
    CircularProgress,
    RadioGroup,
    Radio,
} from '@material-ui/core';
import classNames from 'classnames';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Warning from '@material-ui/icons/Warning';
import ClearIcon from '@material-ui/icons/Clear';

import ButtonComponent from '../../../components/buttons';
import Input from '../../../components/Input';

import companies from '../../../libs/companies.json';
import { postRequest } from '../../../libs/Api';

import {
    getFormErrors,
    getInputProps,
    getFormInitialBlur,
} from '../../../libs/forms';
import { getLanguage } from '../../../libs/i18n';

import commonStyles from '../../../styles/index';
import theme from '../../../theme';

const styles = () => {
    return {
        ...commonStyles.dialogs,
        ...commonStyles.stepper,
        ...commonStyles.forms,
        formContainer: {
            ...commonStyles.forms.root,
            border: 'none',
            padding: 0,
            [theme.breakpoints.down('xs')]: {
                padding: 0,
            },
        },
        main: {
            position: 'fixed',
            right: 0,
            top: '50%',
            marginTop: '-5%',
            height: 1,
            width: 1,
            transform: 'rotate(-90deg)',
            zIndex: 9,
            [theme.breakpoints.down('xs')]: {
                transform: 'rotate(0deg)',
                right: '50%',
                top: 'auto',
                bottom: 0,
            },
        },
        button: {
            ...commonStyles.buttons.root,
            ...commonStyles.buttons.active,
            position: 'absolute',
            top: 'auto',
            right: 0,
            width: 180,
            bottom: '100%',
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
            padding: '13px 16px 10px 16px',
            [theme.breakpoints.down('xs')]: {
                right: '50%',
                marginRight: '-90px',
            },
        },
        extraField: {
            width: '100%',
            marginTop: theme.spacing(2),
        },
        actions: {
            marginTop: theme.spacing(2),
        },
        optin: {
            '& .MuiTypography-body1': {
                fontSize: 12,
            },
        },
        formLabel: {
            color: theme.textColor,
            alignItems: 'center',
            display: 'flex',
            marginRight: theme.spacing(2),
            [theme.breakpoints.down('xs')]: {
                width: '100%',
            },
        },
        requiredText: {
            textAlign: 'center',
            fontSize: 13,
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(1),
        },
        infos: {
            fontSize: 13,
        },
        thanks: {
            fontWeight: 600,
            marginBottom: theme.spacing(2),
        },
    };
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const useStyles = makeStyles(() => styles());

const steps = [1, 2, 3];

const initialForm = {
    company: '', // 'required|string|max:255',
    vat_number: '', // required|string|max:20
    last_name: '', // required|string|max:255
    first_name: '', // required|string|max:255
    email: '', // required|string|email|max:255
    phone: '', // required|string|max:255
    contract_end_date: '', // required|date_format:Y-m-d'
    current_supplier: '', // required|string|max:255
    current_pricing: '', // required|in:fixed,flex
    fixed_price: '', // required_if:current_pricing,fixed|numeric|min:0
    flex_peak_price: '', // required_if:current_pricing,flex|numeric|min:0
    flex_off_peak_price: '', // required_if:current_pricing,flex|numeric|min:0'
    optin: false, // required|boolean
    gas_accounts: [
        {
            ean_code: '',
            annual_consumption: '',
        },
    ],
    electricity_accounts: [
        {
            ean_code: '',
            annual_consumption: '',
        },
    ],
    lang: getLanguage(), // required|in:fr,nl,en
};

const Calculate = ({ open, setOpen }) => {
    const [loading, setIsLoading] = React.useState(false);
    const [submitValue, setSubmitValue] = React.useState(null);
    const [activeStep, setActiveStep] = React.useState(0);
    const [form, setForm] = React.useState(initialForm);
    const [bluredItems, setBlur] = React.useState(getFormInitialBlur(form));
    const classes = useStyles();
    const { t } = useTranslation();

    const errors = getFormErrors(form);
    const handleChangeForm = (key, value, subKey, index) => {
        let newValue;
        if (index !== undefined) {
            newValue = [...form[key]];
            newValue[index][subKey] = value;
        } else {
            newValue = value;
        }
        setForm({
            ...form,
            [key]: newValue,
        });
    };

    const handleBlur = (key, subKey, index) => {
        let newBlur;
        if (index !== undefined) {
            newBlur = [...bluredItems[key]];
            newBlur[index][subKey] = true;
        } else {
            newBlur = true;
        }
        setBlur({
            ...bluredItems,
            [key]: newBlur,
        });
    };

    const handleClickOpen = () => {
        window.dataLayer.push({
            'data-gtmid': 'aside-calculator',
            event: `aside-calculator-click`,
        });
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handlePrevious = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSubmit = () => {
        setSubmitValue(null);
        setIsLoading(true);
        const fullForm = {
            ...form,
        };
        if (fullForm.current_supplier === 'other') {
            fullForm.current_supplier = fullForm.other_supplier;
        }
        fullForm.contract_end_date = moment(fullForm.contract_end_date).format(
            'yyyy-MM-DD',
        );

        if (fullForm.flex_peak_price === '') {
            fullForm.flex_peak_price = 0;
        }
        if (fullForm.flex_off_peak_price === '') {
            fullForm.flex_off_peak_price = 0;
        }
        if (fullForm.fixed_price === '') {
            fullForm.fixed_price = 0;
        }
        fullForm.vat_number = `BE${fullForm.vat_number}`;

        fullForm.gas_accounts.forEach((ga, index) => {
            fullForm.gas_accounts[
                index
            ].ean_code = `5414${fullForm.gas_accounts[index].ean_code}`;
        });
        fullForm.electricity_accounts.forEach((ga, index) => {
            fullForm.electricity_accounts[
                index
            ].ean_code = `5414${fullForm.gas_accounts[index].ean_code}`;
        });
        postRequest(process.env.REACT_APP_DEAL_API, fullForm)
            .then(() => {
                setTimeout(() => {
                    setIsLoading(false);
                    handleNext();
                    setSubmitValue(true);
                });
            })
            .catch(() => {
                setIsLoading(false);
                setSubmitValue(false);
            });
    };

    const fullForm = {
        values: form,
        errors,
        bluredItems,
    };
    const firstStepValues = getFormErrors({
        company: form.company,
        vat_number: form.vat_number,
        last_name: form.last_name,
        first_name: form.first_name,
        email: form.email,
        phone: form.phone,
    });
    let secondStepValues = {
        contract_end_date: form.contract_end_date,
        current_supplier: form.current_supplier,
        current_pricing: form.current_pricing,
    };
    if (secondStepValues.current_pricing === 'flex') {
        secondStepValues.flex_peak_price = form.flex_peak_price;
        secondStepValues.flex_off_peak_price = form.flex_off_peak_price;
    }
    if (secondStepValues.current_supplier === 'other') {
        secondStepValues.other_supplier = form.other_supplier
            ? form.other_supplier
            : '';
    }
    secondStepValues = getFormErrors(secondStepValues, false);

    const isFirstStepValid =
        Object.keys(firstStepValues)
            .map((key) => ({
                key,
                hasError: firstStepValues[key],
            }))
            .filter((e) => e.hasError === true).length === 0;
    const isSecondStepValid =
        Object.keys(secondStepValues)
            .map((key) => ({
                key,
                hasError: secondStepValues[key],
            }))
            .filter((e) => e.hasError === true).length === 0;
    const addGaz = () => {
        const gasAccounts = [...form.gas_accounts];
        const gasAccountBlur = [...bluredItems.gas_accounts];
        gasAccounts.push({
            ean_code: '',
            annual_consumption: '',
        });
        gasAccountBlur.push({
            ean_code: false,
            annual_consumption: false,
        });
        setForm({
            ...form,
            gas_accounts: gasAccounts,
        });
        setBlur({
            ...bluredItems,
            gas_accounts: gasAccountBlur,
        });
    };
    const removeGaz = (gasIndex) => {
        const gasAccounts = [...form.gas_accounts];
        const gasAccountBlur = [...bluredItems.gas_accounts];
        gasAccounts.splice(gasIndex, 1);
        gasAccountBlur.splice(gasIndex, 1);
        setForm({
            ...form,
            gas_accounts: gasAccounts,
        });
        setBlur({
            ...bluredItems,
            gas_accounts: gasAccountBlur,
        });
    };
    const addElec = () => {
        const elecAccounts = [...form.electricity_accounts];
        const elecAccountBlur = [...bluredItems.electricity_accounts];
        elecAccounts.push({
            ean_code: '',
            annual_consumption: '',
        });
        elecAccountBlur.push({
            ean_code: false,
            annual_consumption: false,
        });
        setForm({
            ...form,
            electricity_accounts: elecAccounts,
        });
        setBlur({
            ...bluredItems,
            electricity_accounts: elecAccountBlur,
        });
    };
    const removeElec = (gasIndex) => {
        const elecAccounts = [...form.electricity_accounts];
        const elecAccountBlur = [...bluredItems.electricity_accounts];
        elecAccounts.splice(gasIndex, 1);
        elecAccountBlur.splice(gasIndex, 1);
        setForm({
            ...form,
            electricity_accounts: elecAccounts,
        });
        setBlur({
            ...bluredItems,
            electricity_accounts: elecAccountBlur,
        });
    };

    // console.log('secondStepValues', secondStepValues);
    // console.log('form', form);
    // console.log('errors', errors);
    // console.log('bluredItems', bluredItems);
    // console.log('isFirstStepValid', isFirstStepValid);
    // console.log('isTouched', isTouched);
    return (
        <div className={classes.main}>
            <Button
                className={classes.button}
                onClick={handleClickOpen}
                data-gtmid="aside-calculator"
            >
                {t('calculate.button')}
            </Button>
            <Dialog
                scroll="body"
                classes={{
                    paper: classes.paper,
                }}
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
            >
                <IconButton
                    className={classes.closeButton}
                    onClick={() => handleClose()}
                    size="small"
                >
                    <ClearIcon />
                </IconButton>
                <Typography className={classes.title} variant="h2">
                    {t('calculate.title')}
                </Typography>
                <DialogContent>
                    {activeStep === 0 && (
                        <DialogContentText className={classes.desc}>
                            {t('calculate.descirption')}
                        </DialogContentText>
                    )}
                    <Stepper
                        color="secondary"
                        activeStep={activeStep}
                        alternativeLabel
                        classes={{
                            root: classes.stepper,
                        }}
                    >
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel />
                            </Step>
                        ))}
                    </Stepper>
                </DialogContent>

                {activeStep === 0 && (
                    <Box>
                        <Grid item xs={12} className={classes.formContainer}>
                            <Grid container alignItems="center" spacing={4}>
                                <Grid
                                    container
                                    item
                                    md={6}
                                    xs={12}
                                    className={classNames(
                                        classes.formRow,
                                        classes.formTrick,
                                    )}
                                >
                                    <Input
                                        {...getInputProps(
                                            'company',
                                            true,
                                            t('contact.form.enterprise'),
                                            handleBlur,
                                            handleChangeForm,
                                            fullForm,
                                        )}
                                    />
                                </Grid>
                                <Grid
                                    container
                                    item
                                    md={6}
                                    xs={12}
                                    className={classNames(classes.formRow)}
                                >
                                    <Input
                                        {...getInputProps(
                                            'vat_number',
                                            true,
                                            t('calculate.vatNumber'),
                                            handleBlur,
                                            handleChangeForm,
                                            fullForm,
                                        )}
                                        format="BE ####.###.###"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={classes.formContainer}>
                            <Grid container alignItems="center" spacing={4}>
                                <Grid
                                    container
                                    item
                                    md={6}
                                    xs={12}
                                    className={classNames(
                                        classes.formRow,
                                        classes.formTrick,
                                    )}
                                >
                                    <Input
                                        {...getInputProps(
                                            'last_name',
                                            true,
                                            t('contact.form.name'),
                                            handleBlur,
                                            handleChangeForm,
                                            fullForm,
                                        )}
                                    />
                                </Grid>
                                <Grid
                                    container
                                    item
                                    md={6}
                                    xs={12}
                                    className={classNames(classes.formRow)}
                                >
                                    <Input
                                        {...getInputProps(
                                            'first_name',
                                            true,
                                            t('contact.form.firstName'),
                                            handleBlur,
                                            handleChangeForm,
                                            fullForm,
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={classes.formContainer}>
                            <Grid container alignItems="center" spacing={4}>
                                <Grid
                                    container
                                    item
                                    md={6}
                                    xs={12}
                                    className={classNames(
                                        classes.formRow,
                                        classes.formTrick,
                                    )}
                                >
                                    <Input
                                        {...getInputProps(
                                            'email',
                                            true,
                                            t('contact.form.mail'),
                                            handleBlur,
                                            handleChangeForm,
                                            fullForm,
                                        )}
                                    />
                                </Grid>
                                <Grid
                                    container
                                    item
                                    md={6}
                                    xs={12}
                                    className={classNames(classes.formRow)}
                                >
                                    <Input
                                        {...getInputProps(
                                            'phone',
                                            true,
                                            t('contact.form.phone'),
                                            handleBlur,
                                            handleChangeForm,
                                            fullForm,
                                        )}
                                        type="number"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                )}

                {activeStep === 1 && (
                    <Box>
                        <Grid item xs={12} className={classes.formContainer}>
                            {form.gas_accounts.map((ga, index) => {
                                const isLastItem =
                                    form.gas_accounts.length === index + 1;
                                return (
                                    <Grid
                                        className={classes.relativeContainer}
                                        container
                                        alignItems="center"
                                        spacing={4}
                                        key={`gas_account_${index}`}
                                    >
                                        <Grid
                                            container
                                            item
                                            md={6}
                                            xs={11}
                                            className={classNames(
                                                classes.formRow,
                                                classes.formTrick,
                                            )}
                                        >
                                            <Input
                                                item={{
                                                    key: `gas_account_${index}_ean`,
                                                    value:
                                                        fullForm.values
                                                            .gas_accounts[index]
                                                            .ean_code,
                                                    required: false,
                                                    label: t(
                                                        'calculate.gazEanCode',
                                                    ),
                                                    // error:
                                                    //     fullForm.errors
                                                    //         .gas_accounts[index]
                                                    //         .ean_code,
                                                    blur:
                                                        fullForm.bluredItems
                                                            .gas_accounts[index]
                                                            .ean_code,
                                                }}
                                                handleBlur={() =>
                                                    handleBlur(
                                                        'gas_accounts',
                                                        'ean_code',
                                                        index,
                                                    )
                                                }
                                                handleChangeForm={(
                                                    theKey,
                                                    value,
                                                ) =>
                                                    handleChangeForm(
                                                        'gas_accounts',
                                                        value,
                                                        'ean_code',
                                                        index,
                                                    )
                                                }
                                                format="5414 ##############"
                                                info={t('calculate.eanInfos')}
                                            />
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            md={5}
                                            xs={11}
                                            className={classNames(
                                                classes.formRow,
                                            )}
                                        >
                                            <Input
                                                item={{
                                                    key: `gas_account_${index}_annual_consumption`,
                                                    value:
                                                        fullForm.values
                                                            .gas_accounts[index]
                                                            .annual_consumption,
                                                    required: false,
                                                    label: t(
                                                        'calculate.annualConsumption',
                                                    ),
                                                    // error:
                                                    //     fullForm.errors
                                                    //         .gas_accounts[index]
                                                    //         .annual_consumption,
                                                    blur:
                                                        fullForm.bluredItems
                                                            .gas_accounts[index]
                                                            .annual_consumption,
                                                }}
                                                type="number"
                                                handleBlur={() =>
                                                    handleBlur(
                                                        'gas_accounts',
                                                        'annual_consumption',
                                                        index,
                                                    )
                                                }
                                                handleChangeForm={(
                                                    theKey,
                                                    value,
                                                ) =>
                                                    handleChangeForm(
                                                        'gas_accounts',
                                                        value,
                                                        'annual_consumption',
                                                        index,
                                                    )
                                                }
                                            />
                                        </Grid>

                                        <Grid
                                            container
                                            item
                                            xs={1}
                                            justify="center"
                                            alignContent="center"
                                            className={classNames(
                                                classes.noPadding,
                                                classes.multiFieldActions,
                                            )}
                                        >
                                            {index > 0 && (
                                                <Tooltip
                                                    title={t(
                                                        'calculate.remove',
                                                    )}
                                                >
                                                    <IconButton
                                                        onClick={() =>
                                                            removeGaz(index)
                                                        }
                                                        size="small"
                                                    >
                                                        <RemoveIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                            {isLastItem && (
                                                <Tooltip
                                                    title={t('calculate.add')}
                                                >
                                                    <IconButton
                                                        onClick={() => addGaz()}
                                                        size="small"
                                                    >
                                                        <AddIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </Grid>
                                    </Grid>
                                );
                            })}
                        </Grid>

                        <Grid item xs={12} className={classes.formContainer}>
                            {form.electricity_accounts.map((ga, index) => {
                                const isLastItem =
                                    form.electricity_accounts.length ===
                                    index + 1;
                                return (
                                    <Grid
                                        className={classes.relativeContainer}
                                        container
                                        alignItems="center"
                                        spacing={4}
                                        key={`electric_account_${index}`}
                                    >
                                        <Grid
                                            container
                                            item
                                            md={6}
                                            xs={11}
                                            className={classNames(
                                                classes.formRow,
                                                classes.formTrick,
                                            )}
                                        >
                                            <Input
                                                item={{
                                                    key: `electric_account_${index}_ean`,
                                                    value:
                                                        fullForm.values
                                                            .electricity_accounts[
                                                            index
                                                        ].ean_code,
                                                    required: false,
                                                    label: t(
                                                        'calculate.electricEanCode',
                                                    ),
                                                    // error:
                                                    //     fullForm.errors
                                                    //         .electricity_accounts[
                                                    //         index
                                                    //     ].ean_code,
                                                    blur:
                                                        fullForm.bluredItems
                                                            .electricity_accounts[
                                                            index
                                                        ].ean_code,
                                                }}
                                                handleBlur={() =>
                                                    handleBlur(
                                                        'electricity_accounts',
                                                        'ean_code',
                                                        index,
                                                    )
                                                }
                                                handleChangeForm={(
                                                    theKey,
                                                    value,
                                                ) =>
                                                    handleChangeForm(
                                                        'electricity_accounts',
                                                        value,
                                                        'ean_code',
                                                        index,
                                                    )
                                                }
                                                format="5414 ##############"
                                                info={t('calculate.eanInfos')}
                                            />
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            md={5}
                                            xs={11}
                                            className={classNames(
                                                classes.formRow,
                                            )}
                                        >
                                            <Input
                                                item={{
                                                    key: `electricity_account_${index}_annual_consumption`,
                                                    value:
                                                        fullForm.values
                                                            .electricity_accounts[
                                                            index
                                                        ].annual_consumption,
                                                    required: false,
                                                    label: t(
                                                        'calculate.annualConsumption',
                                                    ),
                                                    // error:
                                                    //     fullForm.errors
                                                    //         .electricity_accounts[
                                                    //         index
                                                    //     ].annual_consumption,
                                                    blur:
                                                        fullForm.bluredItems
                                                            .electricity_accounts[
                                                            index
                                                        ].annual_consumption,
                                                }}
                                                handleBlur={() =>
                                                    handleBlur(
                                                        'electricity_accounts',
                                                        'annual_consumption',
                                                        index,
                                                    )
                                                }
                                                type="number"
                                                handleChangeForm={(
                                                    theKey,
                                                    value,
                                                ) =>
                                                    handleChangeForm(
                                                        'electricity_accounts',
                                                        value,
                                                        'annual_consumption',
                                                        index,
                                                    )
                                                }
                                            />
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            xs={1}
                                            justify="center"
                                            alignContent="center"
                                            className={classNames(
                                                classes.noPadding,
                                                classes.multiFieldActions,
                                            )}
                                        >
                                            {index > 0 && (
                                                <Tooltip
                                                    title={t(
                                                        'calculate.remove',
                                                    )}
                                                >
                                                    <IconButton
                                                        onClick={() =>
                                                            removeElec(index)
                                                        }
                                                        size="small"
                                                    >
                                                        <RemoveIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                            {isLastItem && (
                                                <Tooltip
                                                    title={t('calculate.add')}
                                                >
                                                    <IconButton
                                                        onClick={() =>
                                                            addElec()
                                                        }
                                                        size="small"
                                                    >
                                                        <AddIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </Grid>
                                    </Grid>
                                );
                            })}
                        </Grid>

                        <Grid item xs={12} className={classes.formContainer}>
                            <Grid container alignItems="flex-start" spacing={4}>
                                <Grid
                                    container
                                    item
                                    md={6}
                                    xs={12}
                                    className={classNames(
                                        classes.formRow,
                                        classes.formTrick,
                                    )}
                                >
                                    <Input
                                        {...getInputProps(
                                            'contract_end_date',
                                            true,
                                            t('calculate.contractEndDate'),
                                            handleBlur,
                                            handleChangeForm,
                                            fullForm,
                                        )}
                                        type="date"
                                    />
                                </Grid>
                                <Grid
                                    container
                                    item
                                    md={6}
                                    xs={12}
                                    className={classNames(classes.formRow)}
                                >
                                    <Input
                                        {...getInputProps(
                                            'current_supplier',
                                            true,
                                            t('calculate.currentSupplier'),
                                            handleBlur,
                                            handleChangeForm,
                                            fullForm,
                                        )}
                                        type="select"
                                        options={companies.map((c) => ({
                                            value: c,
                                            label: c,
                                        }))}
                                        other
                                    />
                                    {form.current_supplier === 'other' && (
                                        <div className={classes.extraField}>
                                            <Input
                                                {...getInputProps(
                                                    'other_supplier',
                                                    true,
                                                    t(
                                                        'calculate.otherSupplier',
                                                    ),
                                                    handleBlur,
                                                    handleChangeForm,
                                                    fullForm,
                                                )}
                                            />
                                        </div>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={classes.formContainer}>
                            <Grid container alignItems="flex-start" spacing={4}>
                                <Grid
                                    container
                                    item
                                    md={6}
                                    xs={12}
                                    className={classNames(
                                        classes.formRow,
                                        classes.formTrick,
                                    )}
                                >
                                    <FormLabel className={classes.formLabel}>
                                        {t('calculate.currentPricing')}
                                    </FormLabel>
                                    <div>
                                        <RadioGroup
                                            className={classes.inlineRadio}
                                            name="current_pricing"
                                            value={form.current_pricinglue}
                                            onChange={(e) =>
                                                handleChangeForm(
                                                    'current_pricing',
                                                    e.target.value,
                                                )
                                            }
                                        >
                                            <FormControlLabel
                                                value="fixed"
                                                control={<Radio />}
                                                label={t('calculate.fix')}
                                            />
                                            <FormControlLabel
                                                value="flex"
                                                control={<Radio />}
                                                label={t('calculate.flex')}
                                            />
                                        </RadioGroup>
                                    </div>
                                </Grid>

                                {form.current_pricing === 'fixed' && (
                                    <Grid
                                        container
                                        item
                                        md={6}
                                        xs={12}
                                        className={classNames(classes.formRow)}
                                    >
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            md={6}
                                            className={classNames(
                                                classes.formRow,
                                                classes.noPaddingMobile,
                                            )}
                                        >
                                            <Input
                                                {...getInputProps(
                                                    'fixed_price',
                                                    false,
                                                    t('calculate.fixedPrice'),
                                                    handleBlur,
                                                    handleChangeForm,
                                                    fullForm,
                                                )}
                                                type="money"
                                            />
                                        </Grid>
                                    </Grid>
                                )}

                                {form.current_pricing === 'flex' && (
                                    <Grid
                                        container
                                        item
                                        md={6}
                                        xs={12}
                                        spacing={1}
                                        className={classNames(classes.formRow)}
                                    >
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            md={6}
                                            className={classNames(
                                                classes.formRow,
                                                classes.noPaddingMobile,
                                            )}
                                        >
                                            <Input
                                                {...getInputProps(
                                                    'flex_peak_price',
                                                    true,
                                                    t(
                                                        'calculate.flexPeakPrice',
                                                    ),
                                                    handleBlur,
                                                    handleChangeForm,
                                                    fullForm,
                                                )}
                                                type="money"
                                            />
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            md={6}
                                            className={classNames(
                                                classes.formRow,
                                                classes.noPaddingMobile,
                                                classes.paddingTopMobile,
                                            )}
                                        >
                                            <Input
                                                {...getInputProps(
                                                    'flex_off_peak_price',
                                                    true,
                                                    t(
                                                        'calculate.flexOffPeakPrice',
                                                    ),
                                                    handleBlur,
                                                    handleChangeForm,
                                                    fullForm,
                                                )}
                                                type="money"
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" spacing={4}>
                            <Grid
                                container
                                item
                                xs={12}
                                className={classNames(
                                    classes.formRow,
                                    classes.optin,
                                )}
                            >
                                <FormControlLabel
                                    control={
                                        // eslint-disable-next-line react/jsx-wrap-multilines
                                        <Checkbox
                                            color="default"
                                            checked={form.optin}
                                            className={classes.checkBox}
                                            name={t('contact.form.optin')}
                                            onChange={(e) =>
                                                handleChangeForm(
                                                    'optin',
                                                    e.target.checked,
                                                )
                                            }
                                        />
                                    }
                                    label={t('contact.form.optin')}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                )}
                {activeStep === 2 && (
                    <Box textAlign="center">
                        <Typography className={classes.thanks}>
                            {t('calculate.thanks')}
                        </Typography>
                        <Typography>{t('calculate.thanksdesc')}</Typography>
                    </Box>
                )}
                {submitValue === false && (
                    <Grid container alignItems="center" spacing={4}>
                        <Grid container item xs={12} justify="center">
                            <Typography className={classes.errorContainer}>
                                <Warning className={classes.warningIcon} />
                                {t('calculate.error')}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
                <DialogActions className={classes.actions}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid
                            container
                            item
                            sm={activeStep !== 1 ? 3 : 1}
                            xs={false}
                        />
                        {activeStep === 1 && (
                            <Grid container item sm={5} xs={6}>
                                <ButtonComponent
                                    onClick={handlePrevious}
                                    message={t('calculate.previous')}
                                    large
                                    fullWidth
                                    active
                                />
                            </Grid>
                        )}
                        <Grid
                            container
                            item
                            sm={activeStep !== 1 ? 6 : 5}
                            xs={activeStep !== 1 ? 12 : 6}
                        >
                            {activeStep <= 1 && (
                                <ButtonComponent
                                    disabled={
                                        (activeStep === 0 &&
                                            !isFirstStepValid) ||
                                        (activeStep === 1 && !isSecondStepValid)
                                    }
                                    onClick={
                                        activeStep === 0
                                            ? handleNext
                                            : handleSubmit
                                    }
                                    message={
                                        activeStep === 0
                                            ? t('calculate.next')
                                            : t('calculate.send')
                                    }
                                    active
                                    large
                                    fullWidth
                                />
                            )}
                        </Grid>

                        <Grid
                            container
                            item
                            sm={activeStep !== 1 ? 3 : 1}
                            xs={false}
                        />
                    </Grid>
                </DialogActions>
                <Grid container alignItems="center">
                    {activeStep !== 2 && (
                        <Grid item xs={12}>
                            <Typography className={classes.requiredText}>
                                {t('calculate.required')}
                            </Typography>
                        </Grid>
                    )}
                    {activeStep === 1 && (
                        <Grid item xs={12}>
                            <Typography className={classes.infos}>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: t('calculate.info'),
                                    }}
                                />
                            </Typography>
                        </Grid>
                    )}
                </Grid>
                {loading === true && (
                    <div className={classes.loadingContainer}>
                        <CircularProgress />
                    </div>
                )}
            </Dialog>
        </div>
    );
};
Calculate.defaultProps = {
    open: false,
    setOpen: () => null,
};

Calculate.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
};

export default Calculate;
