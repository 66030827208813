import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import {
    Typography,
    Grid,
    Link,
    FormControl,
    InputLabel,
    OutlinedInput,
    FormControlLabel,
    Checkbox,
    CircularProgress,
} from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Warning from '@material-ui/icons/Warning';

import PhoneIcon from '../../../components/icons/phone';
import MailIcon from '../../../components/icons/mail';

import Button from '../../../components/buttons';

import { getFormErrors } from '../../../libs/forms';
import { postRequest } from '../../../libs/Api';
import { getLanguage } from '../../../libs/i18n';

import commonStyles from '../../../styles/index';

const styles = (theme) => {
    return {
        ...commonStyles.forms,
        root: {
            width: '100%',
            height: '100%',
            padding: '8vh 0',
            ...commonStyles.forms.root,
        },
        container: {
            height: '100%',
            width: '100%',
            padding: 0,
            display: 'flex',
            [theme.breakpoints.down('md')]: {
                padding: theme.spacing(0, 4),
            },
            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(0, 2),
            },
        },
        fourIcons: {
            height: '40vh',
            width: 'auto',
        },
        textContainer: {
            padding: '0 7% 8% 7%',
            textAlign: 'center',
            [theme.breakpoints.down('md')]: {
                padding: '0 5% 8% 5%',
            },
            [theme.breakpoints.down('sm')]: {
                padding: '0 2% 8% 2%',
            },
            [theme.breakpoints.down('xs')]: {
                padding: '0 2%',
            },
        },
        title: {
            marginBottom: theme.spacing(4),
            fontWeight: 500,
            color: theme.palette.primary.main,
            [theme.breakpoints.down('md')]: {
                fontSize: 30,
                marginBottom: theme.spacing(2),
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: 26,
                marginBottom: theme.spacing(2),
            },
        },
        desc: {
            fontSize: 18,
            lineHeight: '30px',
            marginBottom: theme.spacing(8),
            fontWeight: 'lighter',
            [theme.breakpoints.down('md')]: {
                fontSize: 16,
                marginBottom: theme.spacing(3),
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: 16,
                lineHeight: '18px',
                marginBottom: theme.spacing(3),
            },
        },
        link: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            transition: theme.transition(['color']),
            color: 'black',
            '&:hover': {
                color: theme.palette.primary.main,
                textDecoration: 'none',
            },
            [theme.breakpoints.down('md')]: {
                marginLeft: theme.spacing(4),
                marginRight: theme.spacing(4),
            },
            [theme.breakpoints.down('sm')]: {
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(1),
            },
        },
        icon: {
            marginBottom: theme.spacing(1),
            width: 30,
            height: 30,
            [theme.breakpoints.down('xs')]: {
                width: 20,
                height: 20,
            },
        },
        linkText: {
            marginBottom: theme.spacing(1),
            opacity: 0.5,
            fontSize: 14,
            [theme.breakpoints.down('xs')]: {
                fontSize: 12,
            },
        },
        linkInfo: {
            fontSize: 18,
            [theme.breakpoints.down('md')]: {
                fontSize: 14,
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: 14,
            },
        },
    };
};

const initialForm = {
    name: '',
    enterprise: '',
    phone: '',
    mail: '',
    message: '',
    optin: false,
    lang: getLanguage(),
};

const useStyles = makeStyles((theme) => styles(theme));

const Contact = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [form, setForm] = useState(initialForm);
    const [isTouched, setIsTouched] = useState(false);
    const [bluredItems, setBlur] = useState({});
    const [submitValue, setSubmitValue] = useState(null);
    const [loading, setIsLoading] = useState(false);

    const handleChangeForm = (key, value) => {
        setForm({
            ...form,
            [key]: value,
        });
        setIsTouched(true);
    };

    const handleBlur = (key) => {
        setBlur({
            ...bluredItems,
            [key]: true,
        });
    };

    const handleSubmit = () => {
        setSubmitValue(null);
        setIsLoading(true);
        postRequest(process.env.REACT_APP_CONTACT_API, form)
            .then(() => {
                setTimeout(() => {
                    setIsLoading(false);
                    setSubmitValue(true);
                });
            })
            .catch(() => {
                setIsLoading(false);
                setSubmitValue(false);
            });
    };
    const errors = getFormErrors(form);
    const sendDisabled =
        Object.keys(errors)
            .map((key) => ({
                key,
                hasError: errors[key],
            }))
            .filter((e) => e.hasError === true).length !== 0 || !isTouched;
    return (
        <section className={classes.root}>
            <Container className={classes.container} maxWidth="lg">
                <Grid container alignItems="center">
                    <Grid
                        container
                        item
                        sm={6}
                        xs={12}
                        display="flex"
                        justify="center"
                        alignItems="center"
                        className={classes.textContainer}
                    >
                        <Typography className={classes.title} variant="h2">
                            {t('contact.title')}
                        </Typography>
                        <Typography className={classes.desc}>
                            {t('contact.desc')}
                        </Typography>
                        <Grid container alignItems="center" justify="center">
                            <Grid item xs={6}>
                                <Link
                                    href={`tel:${t('contact.phoneNumber')}`}
                                    className={classes.link}
                                    data-gtmid="home-contact-phone"
                                >
                                    <PhoneIcon className={classes.icon} />
                                    <span className={classes.linkText}>
                                        {t('contact.phone')}
                                    </span>
                                    <span className={classes.linkInfo}>
                                        {t('contact.phoneNumber')}
                                    </span>
                                </Link>
                            </Grid>
                            <Grid item xs={6}>
                                <Link
                                    href={`mailto:${t('contact.mail')}`}
                                    className={classes.link}
                                    data-gtmid="home-contact-email"
                                >
                                    <MailIcon className={classes.icon} />
                                    <span className={classes.linkText}>
                                        {t('contact.email')}
                                    </span>
                                    <span className={classes.linkInfo}>
                                        {t('contact.mail')}
                                    </span>
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={6} xs={12} className={classes.formContainer}>
                        <Grid container alignItems="center" spacing={4}>
                            <Grid
                                container
                                item
                                md={6}
                                xs={12}
                                className={classNames(
                                    classes.formRow,
                                    classes.formTrick,
                                )}
                            >
                                <FormControl
                                    variant="outlined"
                                    className={classes.formControl}
                                >
                                    <InputLabel
                                        error={errors.name && bluredItems.name}
                                        htmlFor="name"
                                        className={classes.label}
                                    >
                                        {t('contact.form.name')}
                                    </InputLabel>
                                    <OutlinedInput
                                        required
                                        id="name"
                                        value={form.name}
                                        error={errors.name && bluredItems.name}
                                        onBlur={() => handleBlur('name')}
                                        onChange={(e) =>
                                            handleChangeForm(
                                                'name',
                                                e.target.value,
                                            )
                                        }
                                        label={t('contact.form.name')}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid
                                container
                                item
                                md={6}
                                xs={12}
                                className={classNames(
                                    classes.formRow,
                                    // classes.formTrickTwo,
                                )}
                            >
                                <FormControl
                                    variant="outlined"
                                    className={classes.formControl}
                                >
                                    <InputLabel
                                        error={
                                            errors.enterprise &&
                                            bluredItems.enterprise
                                        }
                                        htmlFor="enterprise"
                                        className={classes.label}
                                    >
                                        {t('contact.form.enterprise')}
                                    </InputLabel>
                                    <OutlinedInput
                                        required
                                        id="enterprise"
                                        value={form.enterprise}
                                        error={
                                            errors.enterprise &&
                                            bluredItems.enterprise
                                        }
                                        onBlur={() => handleBlur('enterprise')}
                                        onChange={(e) =>
                                            handleChangeForm(
                                                'enterprise',
                                                e.target.value,
                                            )
                                        }
                                        label={t('contact.form.enterprise')}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" spacing={4}>
                            <Grid
                                container
                                item
                                md={6}
                                xs={12}
                                className={classNames(
                                    classes.formRow,
                                    classes.formTrick,
                                )}
                            >
                                <FormControl
                                    variant="outlined"
                                    className={classes.formControl}
                                >
                                    <InputLabel
                                        error={
                                            errors.phone && bluredItems.phone
                                        }
                                        htmlFor="phone"
                                        className={classes.label}
                                    >
                                        {t('contact.form.phone')}
                                    </InputLabel>
                                    <OutlinedInput
                                        required
                                        id="phone"
                                        value={form.phone}
                                        error={
                                            errors.phone && bluredItems.phone
                                        }
                                        onBlur={() => handleBlur('phone')}
                                        onChange={(e) =>
                                            handleChangeForm(
                                                'phone',
                                                e.target.value,
                                            )
                                        }
                                        label={t('contact.form.phone')}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid
                                container
                                item
                                md={6}
                                xs={12}
                                className={classNames(
                                    classes.formRow,
                                    // classes.formTrickTwo,
                                )}
                            >
                                <FormControl
                                    variant="outlined"
                                    className={classes.formControl}
                                >
                                    <InputLabel
                                        error={errors.mail && bluredItems.mail}
                                        htmlFor="mail"
                                        className={classes.label}
                                    >
                                        {t('contact.form.mail')}
                                    </InputLabel>
                                    <OutlinedInput
                                        required
                                        id="mail"
                                        value={form.mail}
                                        error={errors.mail && bluredItems.mail}
                                        onBlur={() => handleBlur('mail')}
                                        onChange={(e) =>
                                            handleChangeForm(
                                                'mail',
                                                e.target.value,
                                            )
                                        }
                                        label={t('contact.form.mail')}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" spacing={4}>
                            <Grid
                                container
                                item
                                xs={12}
                                className={classes.formRow}
                            >
                                <FormControl
                                    variant="outlined"
                                    className={classes.formControl}
                                >
                                    <InputLabel
                                        error={
                                            errors.message &&
                                            bluredItems.message
                                        }
                                        htmlFor="message"
                                        className={classes.label}
                                    >
                                        {t('contact.form.message')}
                                    </InputLabel>
                                    <OutlinedInput
                                        required
                                        id="message"
                                        value={form.message}
                                        error={
                                            errors.message &&
                                            bluredItems.message
                                        }
                                        onBlur={() => handleBlur('message')}
                                        multiline
                                        rows={4}
                                        onChange={(e) =>
                                            handleChangeForm(
                                                'message',
                                                e.target.value,
                                            )
                                        }
                                        label={t('contact.form.message')}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" spacing={4}>
                            <Grid
                                container
                                item
                                xs={12}
                                className={classes.formRow}
                            >
                                <FormControlLabel
                                    control={
                                        // eslint-disable-next-line react/jsx-wrap-multilines
                                        <Checkbox
                                            color="default"
                                            checked={form.optin}
                                            className={classes.checkBox}
                                            name={t('contact.form.optin')}
                                            onChange={(e) =>
                                                handleChangeForm(
                                                    'optin',
                                                    e.target.checked,
                                                )
                                            }
                                        />
                                    }
                                    label={t('contact.form.optin')}
                                />
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" spacing={4}>
                            <Grid container item xs={12}>
                                <Button
                                    disabled={sendDisabled}
                                    onClick={() => handleSubmit()}
                                    message={t('contact.form.send')}
                                    active
                                    large
                                    fullWidth
                                    gtmId="home-contact-submit"
                                />
                            </Grid>
                        </Grid>
                        {submitValue === false && (
                            <Grid container alignItems="center" spacing={4}>
                                <Grid container item xs={12}>
                                    <Typography
                                        className={classes.errorContainer}
                                    >
                                        <Warning
                                            className={classes.warningIcon}
                                        />
                                        {t('contact.form.error')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                        <Grid container alignItems="center" spacing={4}>
                            <Grid container item xs={12}>
                                <Typography className={classes.info}>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: t('contact.form.info'),
                                        }}
                                    />
                                </Typography>
                            </Grid>
                        </Grid>
                        {submitValue === true && (
                            <div className={classes.successContainer}>
                                <CheckCircleOutlineIcon
                                    fontSize="large"
                                    className={classes.successIcon}
                                />
                                {t('contact.form.success')}
                            </div>
                        )}
                        {loading === true && (
                            <div className={classes.loadingContainer}>
                                <CircularProgress />
                            </div>
                        )}
                    </Grid>
                </Grid>
            </Container>
        </section>
    );
};

export default Contact;
